import { UpdateOnlineOrderInput } from "./../models/GQL_API";
import { OnlineOrderGetOnlineVariables } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { OnlineOrder } from "../models";
import { createOnlineOrder, updateOnlineOrder } from "../graphql/mutations";
import { getOnlineOrder, listOnlineOrders } from "../graphql/queries";
import { CreateOnlineOrderInput } from "../models/GQL_API";
import { HeadCell } from "../models/dataTable";
import { setListing, setNextToken, setLastIndex, setPagination, setFilter } from "../store/ducks/onlineOrders";
import { OrderStatus } from "../constants/enums";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  let nextToken= useSelector((state: any) => state.onlineOrders.nextToken);
  let lastIndex= useSelector((state: any) => state.onlineOrders.lastIndex);
  let paginationListing = useSelector((state: any) => state.onlineOrders.pagination);
  let paginationFilter = useSelector((state: any) => state.onlineOrders.filter);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);


  function formatDateToYYYYMMDDHHMM(date:any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hour}:${minute}`;
  }
  

  const session = useSelector((state: any) => state.app.session);

  async function getOnlineByID(params: any) {
    try {
      const { id } = params;
      const onlineOrder: any = await API.graphql({
        query: getOnlineOrder,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return onlineOrder.data.getOnlineOrder;
    } catch (err) {
      throw err;
    }
  }

  async function fetchAllOnline(params: any) {
    try {
      const {
        conceptID,
        orderStatusesSelectedFilters,
        adminsSelectedFilters,
        fromDate,
        toDate,
        limit,
        startIndex,
        statusList,
        waiterID
      } = params;

      const filter:any={};
      if(conceptID)
      {
        filter.conceptID={ eq: conceptID }
      }
      if (
        orderStatusesSelectedFilters &&
        orderStatusesSelectedFilters.length > 0
      ) {
        filter.or = [];
        for (let filter of orderStatusesSelectedFilters) {
          filter.or.push({ statusID: { eq: filter.id } });
        }
      }

      if (adminsSelectedFilters && adminsSelectedFilters.length > 0) {
        const adminFilters = [];
        for (let filter of adminsSelectedFilters) {
          adminFilters.push({
            dispatcherID: { eq: filter.Attributes[0].Value },
          });
        }
        filter.or = adminFilters;
      }

      if(fromDate&&toDate)
      {
        filter.and = [];
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        filter.and.push({ createdAt: { ge: formatDateToYYYYMMDDHHMM(new Date(fromDate)) } });
        filter.and.push({ createdAt: { lt: formatDateToYYYYMMDDHHMM(new Date(toDatePlusOneDay)) } });
      }
      if (
        statusList &&
        statusList.length > 0
      ) {
        filter.or = [];
        for (let status of statusList) {
          filter.or.push({ status: { eq: status } });
        }
      }
      if(waiterID)
      {
        filter.waiterID={ eq: waiterID };
      }

      if(paginationFilter&&(paginationFilter.toString()!=[conceptID,orderStatusesSelectedFilters,adminsSelectedFilters,fromDate,toDate].toString()))
      {
        dispatch(setPagination([]));
        dispatch(setNextToken(null));
        dispatch(setLastIndex(0));

        paginationListing=[];
        nextToken=null;
        lastIndex=null;
      }
      if(lastIndex>=startIndex && lastIndex!==null && paginationListing.length>0 && selectedConcept===paginationListing[0].conceptID  )
      {
        return paginationListing.slice(startIndex,startIndex+limit)
      }
      const listing: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: listOnlineOrders,
        variables: { filter, limit: limit ?? 1000 ,nextToken:paginationListing.length>0 &&selectedConcept===paginationListing[0].conceptID ?nextToken:undefined},
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      dispatch(setLastIndex(startIndex));
      dispatch(setNextToken(listing.data.listOnlineOrders.nextToken));
      dispatch(setPagination( paginationListing.concat(listing.data.listOnlineOrders.items)));
      dispatch(setFilter([conceptID,orderStatusesSelectedFilters,adminsSelectedFilters,fromDate,toDate]));

      return listing.data.listOnlineOrders.items;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(params: OnlineOrderGetOnlineVariables) {
    //do it with index
    try {
      const { userID, conceptID, limit } = params;
      const filter: any = {
        userID: { eq: userID },
        conceptID: { eq: conceptID },
      };
      const listing: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: listOnlineOrders,
        variables: { filter, limit: limit ?? 1000 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.listOnlineOrders.items;
    } catch (err) {
      showError(err);
    }
  }

  async function updateOnline(params: any, session: any) {
    try {
      const { orderedItems, deliveryFee ,status,orderAddress,waiterID} = params;

      if(orderedItems)
      {
        for (let i = 0; i < orderedItems.length; i++) {
          delete orderedItems[i].__typename;
        }
      }
      let original = await getOnlineByID(params);

      const updateInput: UpdateOnlineOrderInput = {
        id: original.id,
        status: params.status ? params.status : original.status,
        specialRequest: params.specialRequest
          ? params.specialRequest
          : original.specialRequest,

        _version: original._version,
      };

      if (orderedItems !== null && orderedItems !== undefined) {
        let totalPrice = 0;
        updateInput.orderedItems = orderedItems;

        for (let item of orderedItems) {
          totalPrice = totalPrice + (item?.price ?? 0) * (item?.quantity ?? 0);
        }
        /* Calculate VAT */
        updateInput.totalAmount = totalPrice * 1.14;

        /* Add Delivery Fee */
        if (deliveryFee) {
          updateInput.totalAmount += deliveryFee;
        }

        /* Round up final value */
        updateInput.totalAmount = Number(updateInput.totalAmount.toFixed(2));
      }
      if(status)
      {
        updateInput.status=status
      }
      if(orderAddress)
      {
        updateInput.orderAddress=orderAddress
      }
      if(waiterID)
      {
        updateInput.waiterID=waiterID
      }
      
      const OnlineOrder: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: updateOnlineOrder,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);

      return OnlineOrder.data.updateOnlineOrder;
    } catch (err) {
      console.log(err)
      showError(err);
    }
  }

  async function createOnline(params: any, isAuth = true) {
    let { userID, userName, OrderData } = params;
    try {
      for (let i = 0; i < OrderData.orderedItems.length; i++) {
        delete OrderData.orderedItems[i].__typename;
      }
      const createInput: CreateOnlineOrderInput = {
        cartID: OrderData.cartID,
        conceptID: OrderData.conceptID,
        userID: OrderData.userID,
        userName: OrderData.userName,
        orderedItems: OrderData.orderedItems,
        specialRequest:OrderData.specialRequest,
        status: OrderStatus.pending,
        orderType: OrderData.orderType,
        orderTime: OrderData.orderTime,
        deliveryFee: OrderData.deliveryFee,
        orderAddress:OrderData.orderAddress,
        totalAmount: (
          OrderData.totalPrice * 1.14 +
          OrderData.deliveryFee
        ).toFixed(2),
        createdAt: formatDateToYYYYMMDDHHMM(new Date()),
        createdByID: userID,
        createdByName: userName,
      };

      const OnlineOrder = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: createOnlineOrder,
        variables: { input: createInput },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);

      return OnlineOrder;
    } catch (err) {
      console.log(err);
    }
  }

  async function exportAll(params: any) {
    try {
      const data = await fetchAllOnline(params);

      let exportedData = [];

      for (let OnlineOrder of data!) {
        let row: any = { ...OnlineOrder };

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "checkNumber",
      numeric: false,
      disablePadding: false,
      label: "Order number",
    },
    {
      id: "orderStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "reason",
      numeric: false,
      disablePadding: false,
      label: "Reason",
    },
    {
      id: "orderType",
      numeric: false,
      disablePadding: false,
      label: "Order Type",
    },
    {
      id: "userName",
      numeric: false,
      disablePadding: false,
      label: "User name",
    },
    {
      id: "dispatcherName",
      numeric: false,
      disablePadding: false,
      label: "Dispatcher",
    },
    {
      id: "totalAmount",
      numeric: false,
      disablePadding: false,
      label: "Total",
    },
    {
      id: "deliveryFee",
      numeric: false,
      disablePadding: false,
      label: "delivery Fee",
    },
    {
      id: "orderTime",
      numeric: false,
      disablePadding: false,
      label: "Order time",
    },
    {
      id: "specialRequest",
      numeric: false,
      disablePadding: false,
      label: "Special request",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created at", 
    },
    {
      id: "createdByName",
      numeric: false,
      disablePadding: false,
      label: "Created by",
    },
  ];

  const api: any = {};

  api[`${listingName}Model`] = OnlineOrder as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}CreateOnline`] = createOnline;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}GetOnlineByID`] = getOnlineByID;
  api[`${listingName}UpdateOnline`] = updateOnline;
  api[`${listingName}Fetch`] = fetchAllOnline;
  api[`${listingName}ChangeListing`] = (listing: OnlineOrder[]) =>
    dispatch(setListing(listing));

  return api;
};

export default useResource;
