import { CreateCategoryInput, UpdateCategoryInput } from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { Category } from "../models";
import { createCategory, updateCategory } from "../graphql/mutations";
import { getCategory, listCategories } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import { setListing, setNextToken, setLastIndex, setPagination } from "../store/ducks/category";
import { getDateFormatted } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();

  const session = useSelector((state: any) => state.app.session);
  const categoriesListing = useSelector((state: any) => state.categories.listing);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);

  const nextToken= useSelector((state: any) => state.categories.nextToken);
  const lastIndex= useSelector((state: any) => state.categories.lastIndex);
  const paginationListing = useSelector((state: any) => state.categories.pagination);


  async function fetch(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText ,limit,startIndex } = params;
      const filter: any = {
        conceptID: { eq: conceptID?conceptID:selectedConcept}, 
      };
      if(categoriesListing.length==0 || conceptID!=categoriesListing[0].conceptID || categoriesListing.length<=30)
      {
        if(lastIndex>=startIndex && lastIndex!==null && paginationListing.length>0 && selectedConcept===paginationListing[0].conceptID   )
        {
          if (searchText.length > 0)
          {
            let filteredData=paginationListing.filter((item:any)=>{ return item.name.toLowerCase().includes(searchText.toLowerCase())})
            return filteredData;
          }
          return paginationListing.slice(startIndex,startIndex+limit)
        }
      const listing: any = await API.graphql<GraphQLQuery<Category>>({
        query: listCategories,
        variables: { filter, limit: limit ? 1000 : limit ,nextToken:paginationListing.length>0 && selectedConcept===paginationListing[0]?.conceptID ?nextToken:undefined},
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      dispatch(setLastIndex(startIndex));
      dispatch(setNextToken(listing.data.listCategories.nextToken));
      dispatch(setPagination( paginationListing.concat(listing.data.listCategories.items)));

      const listSorted=listing.data.listCategories.items.sort((a:any, b:any) => {
        return a.precedence - b.precedence;
    })

    if (searchText.length > 0)
      {
        let filteredData=listSorted.filter((item:any)=>{ return item.name.toLowerCase().includes(searchText.toLowerCase())})
        return filteredData;
      }

      return listSorted;
    }
    else
    {
      return categoriesListing;
    }
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const category: any = await API.graphql({
        query: getCategory,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return category.data.getCategory;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    try {
      const createInput: CreateCategoryInput = {
        name: data.name.toLowerCase(),
        image: data.image ? (data.image.fileUrl ? data.image.fileUrl : data.image) :"",
        
        precedence: data.precedence ? data.precedence : "0",
        conceptID: data.conceptID,
        createdAt: getDateFormatted(new Date()),
        createdByID: userID,
        createdByName: userName,
      };

      const Category = await API.graphql<GraphQLQuery<Category>>({
        query: createCategory,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);

      return Category;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      const updateInput: UpdateCategoryInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase() : original.name,
        image: data.image
          ? data.image.fileUrl
            ? data.image.fileUrl
            : data.image
          : original!.image,
        precedence: data.precedence ? data.precedence : original.precedence,
        _version: original._version,
      };

      const Category: any = await API.graphql<GraphQLQuery<Category>>({
        query: updateCategory,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);

      return Category.data.updateCategory;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: Category[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = Category as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: Category[]) =>
    dispatch(setListing(listing));

  return api;
};

export default useResource;
