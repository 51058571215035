import { Auth, API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setDispatcherListing,
  setWaiterListing,
  setFilters,
  setListing,
  setSelected,
  setSelectedFilters,
} from "../store/ducks/admins";
import { HeadCell } from "../models/dataTable";
import { getDomainName } from "../helpers/utils";
import { AdminsApiPaths, AdminsConstants } from "../constants/enums";
import useApp from "./useApp";
import * as AWS from "aws-sdk";
import awsmobile from "../aws-exports";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const account = useSelector((state: any) => state.accounts.selected);

  const COGNITO_GROUPS = [
    { label: "admin", value: "admin" },
    { label: "waiter", value: "waiter" },
  ];
  const DEVELOP_COGNITO_USER_POOL_ID = awsmobile.aws_user_pools_id;
  const DEVELOP_COGNITO_IDENTITY_POOL_ID =
    awsmobile.aws_cognito_identity_pool_id;

  // const DEVELOP_COGNITO_USER_POOL_ID = "us-east-2_NosZWGyDt";

  // const PRODUCTION_COGNITO_USER_POOL_ID = "us-east-2_tgJIDJguq";
  // const DEVELOP_COGNITO_IDENTITY_POOL_ID =
  //   "us-east-2:4489a2e0-7c5c-4ab2-9f3f-27e63ddefa09";
  // const PRODUCTION_COGNITO_IDENTITY_POOL_ID =
  //   "us-east-2:94bc64c4-5c4b-4997-b5e2-16cca244ada9";

  async function create(data: any) {
    try {
      const user = await Auth.signUp({
        username: `${getDomainName(account)}_${data.email}`,
        password: data.password,
        attributes: {
          name: data.name,
          email: `${getDomainName(account)}_${data.email}`,
        },
      });

      await addUserToGroup(
        user.user.getUsername(),
        data.group ? data.group : "admin"
      );
    } catch (err: Error | any) {
      // console.log(err);
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function adminsConfig(apiPath: string, queryString: any, body?: any) {
    let apiName = AdminsConstants.ADMINS_API;
    let path = apiPath;

    let myInit = {
      body: body,

      queryStringParameters: queryString,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return {
      apiName,
      path,
      myInit,
    };
  }

  async function listGroupsForUser(username: string) {
    let groups: any[] = [];
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.LIST_GROUPS_FOR_USER,
        {
          username,
        }
      );
      const { Groups } = await API.get(apiName, path, myInit);
      for (let group of Groups) {
        groups.push(group["GroupName"]);
      }
      return groups;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function listAllGroups() {
    let groups: any[] = [];
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.LIST_GROUPS,
        { limit: 60 }
      );
      const { Groups } = await API.get(apiName, path, myInit);
      for (let group of Groups) {
        groups.push(group["GroupName"]);
      }
      return groups;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function addUserToGroup(username: string, groupname: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.ADD_USER_TO_GROUP,
        {},
        {
          username,
          groupname,
        }
      );
      const { ...result } = await API.post(apiName, path, myInit);
      showConfirm(result.message);
    } catch (err: Error | any) {
      // console.log(err);
      showError(err.message);
    }
  }

  async function removeUserFromGroup(username: string, groupname: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.REMOVE_USER_FROM_GROUP,
        {},
        {
          username,
          groupname,
        }
      );
      const { ...result } = await API.post(apiName, path, myInit);
      showConfirm(result.message);
    } catch (err: Error | any) {
      // console.log(err);
      showError(err.message);
    }
  }

  async function fetch(nextToken: string, searchText: string, limit: number) {
    try {
      if(searchText.length > 0 && listing) { 
         
          let filteredData = listing.filter((item: any) => {
            return (
              item.email &&
              item.email.toLowerCase().includes(searchText.toLowerCase())
            );
          });
          dispatch(setFilters(filteredData));
        
      }else{
        
        let listingUsers = await byGroup(nextToken, limit, "admin");
        let admins = [];
        for (let user of listingUsers) {
          let { Attributes, ...userData } = user;
          
          for (let attr of user["Attributes"]) {
            userData[attr["Name"]] = attr["Value"];
            if (userData["Username"]) {
            userData["id"] = userData["Username"];
          }
          if (userData["UserCreateDate"]) {
            userData["createdAt"] = userData["UserCreateDate"];
          }
          if (userData["email"]) {
            userData["email"] = userData["email"].split(
              `${getDomainName(account)}_`
              )[1];
            }
          }
          //get user groups
          if (userData["Username"]) {
          admins.push(userData);
        }
      }
      
      if (searchText.length > 0) {
        
        let filteredData = admins.filter((item: any) => {
          return (
            item.email &&
            item.email.toLowerCase().includes(searchText.toLowerCase())
            );
          });
          dispatch(setListing(filteredData));
        } else {
          dispatch(setListing(admins));
        }
      }
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
    // try {
    //   const { apiName, path, myInit } = await adminsConfig(
    //     AdminsApiPaths.LIST_USERS,
    //     {
    //       limit: limit,
    //       token: nextToken,
    //     }
    //   );

    //   const { NextToken, ...data } = await API.get(apiName, path, myInit);
    //   nextToken = NextToken === undefined ? "" : NextToken;
    //   let listing: any[] = [];
    //   let cognitoObj = data.Users;

    // for (let user of cognitoObj) {
    //   let { Attributes, ...userData } = user;

    //   for (let attr of user["Attributes"]) {
    //     userData[attr["Name"]] = attr["Value"];
    //     if (userData["Username"]) {
    //       userData["id"] = userData["Username"];
    //     }
    //     if (userData["UserCreateDate"]) {
    //       userData["createdAt"] = userData["UserCreateDate"];
    //     }
    //     if (userData["email"]) {
    //       userData["email"] = userData["email"].split(
    //         `${GetDomainName(account)}_`
    //       )[1];
    //     }
    //   }
    //   //get user groups
    //   if (userData["Username"] && userData["Groups"]!="dispatcher") {
    //     userData["Groups"] = await listGroupsForUser(userData["Username"]);
    //   }
    //   if(userData["Groups"]!="dispatcher")
    //   {
    //     listing.push(userData);
    //   }
    // }

    // if (searchText.length > 0)
    // {
    //   let filteredData=listing.filter((item:any)=>{ return item.email.toLowerCase().includes(searchText.toLowerCase())})
    //   dispatch(setListing(filteredData));
    // }
    // else
    // {
    //   dispatch(setListing(listing));
    // }

    //   // listing = [...useData]
    // } catch (err: Error | any) {
    //   showError(
    //     typeof err.message === "string" ? err.message : "Error occurred"
    //   );
    // }
  }

  async function byGroup(nextToken: string, limit: number, groupName: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.LIST_USERS_IN_GROUP,
        {
          groupname: groupName,
          // limit: limit,
          token: nextToken,
        }
      );

      const { NextToken, ...data } = await API.get(apiName, path, myInit);

      nextToken = NextToken;
      var listing = data.Users;

      let groupMembers = [];
      while (nextToken) {
        let { apiName, path, myInit } = await adminsConfig(
          AdminsApiPaths.LIST_USERS_IN_GROUP,
          {
            groupname: groupName,
            // limit: limit,
            token: nextToken,
          }
        );
        let { NewNextToken, ...Nextdata } = await API.get(
          apiName,
          path,
          myInit
        );
        nextToken = Nextdata.NextToken;
        listing = listing.concat(Nextdata.Users);
      }

      for (let user of listing) {
        let { Attributes, ...userData } = user;

        for (let attr of user["Attributes"]) {
          userData[attr["Name"]] = attr["Value"];
          if (userData["Username"]) {
            userData["id"] = userData["Username"];
          }
          if (userData["UserCreateDate"]) {
            userData["createdAt"] = userData["UserCreateDate"];
          }
          if (userData["email"]) {
            userData["email"] = userData["email"].split(
              `${getDomainName(account)}_`
            )[1];
          }
        }
        if (userData["Username"]) {
          groupMembers.push(userData);
        }
      }
      switch (groupName) {
        case "admin":
          dispatch(setListing(groupMembers));
          break;
        case "waiter":
          dispatch(setWaiterListing(groupMembers));
          break;
      }

      return listing;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function get(username: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.GET_USER,
        {
          username,
        }
      );

      const { ...single } = await API.get(apiName, path, myInit);
      let { UserAttributes, ...userData } = single;

      for (let attr of single["UserAttributes"]) {
        userData[attr["Name"]] = attr["Value"];
        if (userData["Username"]) {
          userData["id"] = userData["Username"];
        }
        if (userData["UserCreateDate"]) {
          userData["createdAt"] = userData["UserCreateDate"];
        }
        if (userData["email"]) {
          userData["email"] = userData["email"].split(
            `${getDomainName(account)}_`
          )[1];
        }

        // get user groups
      }
      if (userData["Username"]) {
        userData["Groups"] = await listGroupsForUser(userData["Username"]);
      }
      return userData;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function enableUser(username: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.ENABLE_USER,
        {},
        {
          username,
        }
      );
      const { ...result } = await API.post(apiName, path, myInit);
      // console.log(result.message);
      showConfirm("User enabled successfully.");
    } catch (err: Error | any) {
      // console.log(err);
      showError(err.message);
    }
  }

  async function disableUser(username: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.DISABLE_USER,
        {},
        {
          username,
        }
      );
      const { ...result } = await API.post(apiName, path, myInit);
      // console.log(result.message);
      showConfirm("User disabled successfully.");
    } catch (err: Error | any) {
      // console.log(err);
      showError(err.message);
    }
  }

  async function deleteUser(username: any) {
    console.log({
      username,
    });
    const credentials = await Auth.currentCredentials();
    AWS.config.credentials = credentials;
    AWS.config.region = "us-east-2";
    new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: DEVELOP_COGNITO_IDENTITY_POOL_ID,
      },
      { credentials: credentials }
    );
    const cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();
    cognitoidentityserviceprovider.adminDeleteUser(
      {
        UserPoolId: DEVELOP_COGNITO_USER_POOL_ID,
        Username: username.id,
      },
      function (err, data) {
        if (data) {
          let newList = listing.filter((admin: any) => {
            return admin.sub !== username.id;
          });
          dispatch(setListing(newList));

          showConfirm("User deleted successfully.");
        }
        if (err) {
          console.log("error deleting user", { err });
          showError(
            typeof err.message === "string"
              ? err.message
              : "Error occurred while deleting the user"
          );
        }
      }
    );
  }

  async function adminChangeUserPassword(username: string, password: string) {
    const credentials = await Auth.currentCredentials();
    AWS.config.credentials = credentials;
    AWS.config.region = "us-east-2";
    new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: DEVELOP_COGNITO_IDENTITY_POOL_ID,
      },
      { credentials: credentials }
    );
    const cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();
    cognitoidentityserviceprovider.adminSetUserPassword(
      {
        UserPoolId: DEVELOP_COGNITO_USER_POOL_ID,
        Username: username,
        Password: password,
        Permanent: true,
      },
      function (err, data) {
        if (data) {
          showConfirm("User password has changed successfully.");
        }
        if (err) {
          showError("Unauthorized Action !");
          // console.log("error changing user password", { err });
          // showError(
          //   typeof err.message === "string"
          //     ? err.message
          //     : "Error occurred while changing user password"
          // );
        }
      }
    );
  }

  async function adminUpdateUserAttributes(
    username: string,
    email: string,
    name: string
  ) {
    const userAttrs: any[] = [];
    if (email) {
      userAttrs.push({
        Name: "email",
        Value: `${getDomainName(account)}_${email}`,
      });
      userAttrs.push({
        Name: "email_verified",
        Value: "true",
      });
    }
    if (name) userAttrs.push({ Name: "name", Value: name });

    const credentials = await Auth.currentCredentials();
    AWS.config.credentials = credentials;
    AWS.config.region = "us-east-2";
    new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: DEVELOP_COGNITO_IDENTITY_POOL_ID,
      },
      { credentials: credentials }
    );
    const cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();

    cognitoidentityserviceprovider.adminUpdateUserAttributes(
      {
        UserPoolId: DEVELOP_COGNITO_USER_POOL_ID,
        Username: username,
        UserAttributes: userAttrs,
      },
      function (err, data) {
        if (data) {
          showConfirm("User data has changed successfully.");
        }
        if (err) {
          showError(
            typeof err.message === "string"
              ? err.message
              : "Error occurred while changing user data"
          );
        }
      }
    );
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.changePassword(user, oldPassword, newPassword);
      showConfirm(result);
    } catch (err: Error | any) {
      // console.log(err);
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["email", "name"];

  function isAdmin(session: any) {
    if (session && session["cognito:groups"]) {
      return (
        session["cognito:groups"].find((group: any) => group === "admin") !==
        undefined
      );
    }
  }

  async function isCurrentAuthUser(resourceUsername: string): Promise<boolean> {
    const { username } = await Auth.currentAuthenticatedUser();
    return username === resourceUsername;
  }

  const options: any[] = [];

  for (let option of listing) {
    options.push({ label: option.name, value: option.sub });
  }
  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;

  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByGroup`] = byGroup;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = () => {};
  api[`${listingName}Trash`] = () => {};
  api[`${listingName}BulkTrash`] = () => {};
  api[`${listingName}Delete`] = deleteUser;
  api[`${listingName}UpdateUserAttrs`] = adminUpdateUserAttributes;
  api[`${listingName}ChangeUserPassword`] = adminChangeUserPassword;
  api[`${listingName}ChangeListing`] = (listing: any[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api["isAdmin"] = isAdmin;
  api["isCurrentAuthUser"] = isCurrentAuthUser;
  api["changePassword"] = changePassword;
  api[`${listingName}ListGroupsForUser`] = listGroupsForUser;
  api[`${listingName}ListAllGroups`] = listAllGroups;
  api[`addUserToGroup`] = addUserToGroup;
  api[`removeUserFromGroup`] = removeUserFromGroup;
  api[`${listingName}DisableUser`] = disableUser;
  api[`${listingName}EnableUser`] = enableUser;
  api[`${listingName}CognitoGroups`] = COGNITO_GROUPS;
  api[`${listingName}ChangeListingForGroup`] = (listing: any[]) => {
    dispatch(setDispatcherListing(listing));
    dispatch(
      setFilters(
        listing.map((model: any) =>
          model.Attributes[2] ? model.Attributes[2].Value : model.Username
        )
      )
    );
  };
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));

  return api;
};

export default useResource;
