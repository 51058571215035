import {
  CreateVariables,
  ServingAreaBulkTrashVariables,
  ServingAreaUpdateVariables,
  Option,
} from "./../models/app";
import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/servingArea";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { ServingArea } from "../models";
import {
  ServingAreaGetVariables,
  ServingAreaListingVariables,
} from "../models/app";
import { CreateServingAreaInput } from "../models/GQL_API";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: ServingAreaListingVariables) {
    const { conceptID, startIndex, limit, searchText } = params;
    try {
      const listing = await DataStore.query(
        ServingArea as any,
        (model: any) => {
          model.conceptID("eq", conceptID).deleted("eq", "0");

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: ServingAreaGetVariables) {
    const { id, listing } = params;

    try {
      const single: ServingArea | undefined =
        listing.length === 0
          ? await DataStore.query(ServingArea as any, id)
          : listing.find((model: ServingArea) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateServingAreaInput = {
        name: data.name,
        fees: data.fees,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      await DataStore.save(new ServingArea(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: ServingAreaUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      await DataStore.save(
        ServingArea.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name : original!.name;
          updated.fees = data.fees ? data.fees : original!.fees;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ServingAreaGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        ServingArea.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ServingAreaBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: ServingAreaGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: ServingArea[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "category"];

  const api: any = {};

  api[`${listingName}Model`] = ServingArea as any;
  api[`${listingName}Options`] = options;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: ServingArea[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
