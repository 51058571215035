import {
  AuthModeStrategyType,
  DataStore,
  Hub,
  syncExpression,
} from "aws-amplify";
import { LOCAL_STORAGE } from "../constants/enums";
import {
  Account,
  Feature,
  Language,
  Concept,
  Group,
  GroupSetting,
  Booking,
  BookingGuest,
  ReservationStatus,
  Comment,
  Timeline,
  User,
  UserConcepts,
  Transaction,
  TimeSlot,
  Area,
  PlanItem,
  Table,
  Notification,
} from "../models";
import { getDateFormatted } from "./utils";

export const disableSync = (bookingID: string) => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 200,
  syncExpressions: [
    // Sync BookingGuest data
    syncExpression(BookingGuest, () => {
      return (model) =>
        bookingID
          ? model.bookingID("eq", bookingID)
          : model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Account data
    syncExpression(Account, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Feature data
    syncExpression(Feature, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Language data
    syncExpression(Language, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Concept data
    syncExpression(Concept, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Group data
    syncExpression(Group, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync GroupSettings data
    syncExpression(GroupSetting, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync TimeSlot data
    syncExpression(TimeSlot, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Area data
    syncExpression(Area, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync PlanItem data
    syncExpression(PlanItem, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Table data
    syncExpression(Table, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Notification data
    syncExpression(Notification, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync User data
    syncExpression(User, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync UserConcepts data
    syncExpression(UserConcepts, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Booking data
    syncExpression(Booking, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync ReservationStatus data
    syncExpression(ReservationStatus, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Timeline data
    syncExpression(Timeline, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Comment data
    syncExpression(Comment, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
    // Never sync Transaction data
    syncExpression(Transaction, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
  ],
});

export const syncGuestsListener = (cb: () => void) => {
  return Hub.listen("datastore", async (capsule) => {
    const {
      payload: { event },
    } = capsule;

    // console.warn({ event });

    if (event === "ready") {
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUESTS, "false");

      cb();
    }
  });
};

export const syncGuestsConfig = () => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 100,
  syncExpressions: [
    // Sync User data
    syncExpression(User, () => {
      return (model) => model.id("ne", "NEVER_MATCH_ME");
    }),

    // Sync Timeline data
    syncExpression(Timeline, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Comment data
    syncExpression(Comment, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Booking data
    syncExpression(Booking, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
  ],
});

export const syncBookings = async (): Promise<void> => {
  const syncBookings: string | null = localStorage.getItem(
    LOCAL_STORAGE.SYNC_BOOKINGS
  );

  if (syncBookings && syncBookings === "true") {
    await DataStore.stop();
    await DataStore.start();
  }
};

export const syncBookingsConfig = (
  userConcepts: UserConcepts,
  fromDate: Date,
  toDate: Date
) => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 2000,
  syncExpressions: [
    // Never sync User data
    syncExpression(User, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Timeline data
    syncExpression(Timeline, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Comment data
    // syncExpression(Comment, () => {
    //   return (model) => model.id("eq", "NEVER_MATCH_ME");
    // }),

    // Never sync Timeline data
    syncExpression(Booking, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Booking data (commented to get booking online)
    // syncExpression(Booking, () => {
    //   if (userConcepts) {
    //     return (model) =>
    //       model
    //         .id("ne", "NEVER_MATCH_ME")
    //         .deleted("eq", "0")
    //         .or((model) => {
    //           for (let conceptID of userConcepts.concepts!) {
    //             if (conceptID) model.conceptID("eq", conceptID);
    //           }

    //           return model;
    //         })
    //         .date("ge", getDateFormatted(fromDate))
    //         .date("le", getDateFormatted(toDate));
    //   } else {
    //     return (model) =>
    //       model
    //         .id("ne", "NEVER_MATCH_ME")
    //         .deleted("eq", "0")
    //         .date("ge", getDateFormatted(fromDate))
    //         .date("le", getDateFormatted(toDate));
    //   }
    // }),
  ],
});

export const syncBookingsListener = (cb: () => void) => {
  const syncBookings: string | null = localStorage.getItem(
    LOCAL_STORAGE.SYNC_BOOKINGS
  );

  if (syncBookings && syncBookings === "true") {
    return Hub.listen("datastore", async (capsule) => {
      const {
        payload: { event },
      } = capsule;

      if (event === "ready") {
        localStorage.setItem(LOCAL_STORAGE.SYNC_BOOKINGS, "false");

        cb();
      }
    });
  }
};

export const syncBookingGuestListener = (cb: () => void) => {
  return Hub.listen("datastore", async (capsule) => {
    const {
      payload: { event },
    } = capsule;

    if (event === "ready") {
      localStorage.setItem(LOCAL_STORAGE.BOOKING_GUEST, "false");

      cb();
    }
  });
};

export const syncBookingGuestConfig = (
  userConcepts: UserConcepts,
  fromDate: Date,
  toDate: Date,
  bookingID: string | undefined,
  guestID: string | undefined
) => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 2000,
  syncExpressions: [
    // Sync Booking Guest
    syncExpression(BookingGuest, () => {
      return (model) =>
        bookingID
          ? model.bookingID("eq", bookingID)
          : model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Transaction data
    syncExpression(Transaction, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync User data
    syncExpression(User, () => {
      return (model) =>
        guestID ? model.id("eq", guestID) : model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Timeline data
    syncExpression(Timeline, () => {
      return (model) =>
        bookingID
          ? model.bookingId("eq", bookingID)
          : model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Comment data
    syncExpression(Comment, () => {
      return (model) =>
        bookingID
          ? model.bookingId("eq", bookingID)
          : model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Timeline data
    syncExpression(Booking, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Booking data (commented to get booking online)
    // syncExpression(Booking, () => {
    //   if (userConcepts) {
    //     return (model) =>
    //       model
    //         .id("ne", "NEVER_MATCH_ME")
    //         .deleted("eq", "0")
    //         .or((model) => {
    //           for (let conceptID of userConcepts.concepts!) {
    //             if (conceptID) model.conceptID("eq", conceptID);
    //           }

    //           return model;
    //         })
    //         .date("ge", getDateFormatted(fromDate))
    //         .date("le", getDateFormatted(toDate));
    //   } else {
    //     return (model) =>
    //       model
    //         .id("ne", "NEVER_MATCH_ME")
    //         .deleted("eq", "0")
    //         .date("ge", getDateFormatted(fromDate))
    //         .date("le", getDateFormatted(toDate));
    //   }
    // }),
  ],
});

export const syncOldBookingConfig = (bookingID: string | undefined) => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 2000,
  syncExpressions: [
    // Sync Timeline data
    syncExpression(Timeline, () => {
      return (model) =>
        bookingID
          ? model.bookingId("eq", bookingID)
          : model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Comment data
    syncExpression(Comment, () => {
      return (model) =>
        bookingID
          ? model.bookingId("eq", bookingID)
          : model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync User data
    syncExpression(User, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Booking data
    syncExpression(Booking, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Transaction data
    syncExpression(Transaction, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),
  ],
});

export const syncBookingGuest = async (): Promise<void> => {
  const syncBookingGuest: string | null = localStorage.getItem(
    LOCAL_STORAGE.BOOKING_GUEST
  );

  if (syncBookingGuest && syncBookingGuest === "true") {
    await DataStore.stop();
    await DataStore.start();
  }
};

export const syncSearchGuestListener = (cb: () => void) => {
  return Hub.listen("datastore", async (capsule) => {
    const {
      payload: { event },
    } = capsule;

    if (event === "ready") {
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, "false");

      cb();
    }
  });
};

export const syncGuestSearchConfig = (
  userConcepts: UserConcepts,
  fromDate: Date,
  toDate: Date,
  searchTerm: string
) => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 2000,
  syncExpressions: [
    // Sync User data
    syncExpression(User, () => {
      return (model) => model.phone_number("beginsWith", searchTerm);
    }),

    // Sync Timeline data
    syncExpression(Timeline, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Comment data
    syncExpression(Comment, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Timeline data
    syncExpression(Booking, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Booking data (commented to get booking online)
    // syncExpression(Booking, () => {
    //   if (userConcepts) {
    //     return (model) =>
    //       model
    //         .id("ne", "NEVER_MATCH_ME")
    //         .deleted("eq", "0")
    //         .or((model) => {
    //           for (let conceptID of userConcepts.concepts!) {
    //             if (conceptID) model.conceptID("eq", conceptID);
    //           }

    //           return model;
    //         })
    //         .date("ge", getDateFormatted(fromDate))
    //         .date("le", getDateFormatted(toDate));
    //   } else {
    //     return (model) =>
    //       model
    //         .id("ne", "NEVER_MATCH_ME")
    //         .deleted("eq", "0")
    //         .date("ge", getDateFormatted(fromDate))
    //         .date("le", getDateFormatted(toDate));
    //   }
    // }),
  ],
});

export const syncSearchGuest = async (): Promise<void> => {
  const syncSearchGuests: string | null = localStorage.getItem(
    LOCAL_STORAGE.GUESTS_SEARCH
  );

  if (syncSearchGuests && syncSearchGuests === "true") {
    await DataStore.stop();
    await DataStore.start();
  }
};

export const syncBookingDateListener = (cb: () => void) => {
  return Hub.listen("datastore", async (capsule) => {
    const {
      payload: { event },
    } = capsule;

    if (event === "ready") {
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE_RANGE, "false");

      cb();
    }
  });
};

export const syncBookingDateConfig = (
  userConcepts: UserConcepts,
  fromDate: Date,
  toDate: Date
) => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 2000,
  syncExpressions: [
    // Never sync User data
    syncExpression(User, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Timeline data
    syncExpression(Timeline, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Comment data
    syncExpression(Comment, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync booking guests data (need testing - for export booking)
    syncExpression(BookingGuest, () => {
      return (model) => model.deleted("eq", "0");
    }),

    // Never sync Timeline data
    syncExpression(Booking, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Booking data (commented to get booking online)
    // syncExpression(Booking, () => {
    //   if (userConcepts) {
    //     return (model) =>
    //       model
    //         .id("ne", "NEVER_MATCH_ME")
    //         .deleted("eq", "0")
    //         .or((model) => {
    //           for (let conceptID of userConcepts.concepts!) {
    //             if (conceptID) model.conceptID("eq", conceptID);
    //           }

    //           return model;
    //         })
    //         .date("ge", getDateFormatted(fromDate))
    //         .date("le", getDateFormatted(toDate));
    //   } else {
    //     return (model) =>
    //       model
    //         .id("ne", "NEVER_MATCH_ME")
    //         .deleted("eq", "0")
    //         .date("ge", getDateFormatted(fromDate))
    //         .date("le", getDateFormatted(toDate));
    //   }
    // }),
  ],
});

export const syncBookingDate = async (): Promise<void> => {
  const bookingDateRange: string | null = localStorage.getItem(
    LOCAL_STORAGE.BOOKING_DATE_RANGE
  );

  if (bookingDateRange && bookingDateRange === "true") {
    await DataStore.stop();
    await DataStore.start();
  }
};

export const syncBookingsRefreshListener = (cb: () => void) => {
  return Hub.listen("datastore", async (capsule) => {
    const {
      payload: { event },
    } = capsule;

    // console.warn({ event });

    if (event === "ready") {
      localStorage.setItem(LOCAL_STORAGE.BOOKING_REFRESH, "false");

      cb();
    }
  });
};

export const syncBookingsRefresh = async (): Promise<void> => {
  const bookingsRefresh: string | null = localStorage.getItem(
    LOCAL_STORAGE.BOOKING_REFRESH
  );

  if (bookingsRefresh && bookingsRefresh === "true") {
    await DataStore.stop();
    await DataStore.start();
  }
};

export const syncGuestStatsListener = (cb: () => void) => {
  return Hub.listen("datastore", async (capsule) => {
    const {
      payload: { event },
    } = capsule;

    // console.warn({ event });

    if (event === "ready") {
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUEST_STATS, "false");

      cb();
    }
  });
};

export const syncGuestStatsConfig = (stats: string[]) => ({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  maxRecordsToSync: 2000,
  syncExpressions: [
    // Never sync User data
    syncExpression(User, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Timeline data
    syncExpression(Timeline, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Comment data
    syncExpression(Comment, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Never sync Timeline data
    syncExpression(Booking, () => {
      return (model) => model.id("eq", "NEVER_MATCH_ME");
    }),

    // Sync Booking data (commented to get booking online)
    // syncExpression(Booking, () => {
    //   return (model) =>
    //     model
    //       .id("ne", "NEVER_MATCH_ME")
    //       .deleted("eq", "0")
    //       .or((model) => {
    //         for (let id of stats) {
    //           model.id("eq", id);
    //         }

    //         return model;
    //       });
    // }),
  ],
});

export const syncGuestStats = async (): Promise<void> => {
  const syncGuestStats: string | null = localStorage.getItem(
    LOCAL_STORAGE.SYNC_GUEST_STATS
  );

  if (syncGuestStats && syncGuestStats === "true") {
    await DataStore.stop();
    await DataStore.start();
  }
};
