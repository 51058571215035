import { listConcepts } from "./../graphql/queries";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setBranches,
  setListing,
  setSelected,
  setFilters,
  setSelectedFilters,
} from "../store/ducks/concept";
import { Concept } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { CreateConceptInput } from "../models/GQL_API";
import {
  ConceptBulkTrashVariables,
  ConceptUpdateVariables,
  CreateVariables,
  ConceptGetVariables,
  ConceptListingVariables,
  Option,
} from "../models/app";
import { getConcept } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { LOCAL_STORAGE } from "../constants/enums";

const useResource = (listingName: string, singleName: string) => {
  const session = useSelector((state: any) => state.app.session);
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: ConceptListingVariables) {
    const { accountID, name, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        Concept as any,
        (model: any) => {
          model.deleted("eq", "0");
          if (accountID) model.accountID("eq", accountID);
          if (name) model.name("eq", name);
          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          // Sort by precedence /createdAt
          sort: (s) => s.precedence(SortDirection.ASCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function fetchOnline() {
    const filter: any = {
      deleted: { eq: "0" },
    };

    try {
      const listing: any = await API.graphql({
        query: listConcepts,
        variables: { filter, limit: 100 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return listing.data.listConcepts.items;
    } catch (err) {
      throw err;
    }
  }

  async function get(params: ConceptGetVariables) {
    const { id, listing } = params;

    try {
      const single: Concept | undefined =
        listing.length === 0
          ? await DataStore.query(Concept as any, id)
          : listing.find((model: any) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(id: string, isAuth: boolean) {
    try {
      const user: any = await API.graphql({
        query: getConcept,
        variables: { id: id },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return user.data.getConcept;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const seats = [
        data.seatTypes_Bar ? "Bar" : null,
        data.seatTypes_Table ? "Table" : null,
      ];
      const areas = [
        data.areas_Indoor ? "Indoor" : null,
        data.areas_Outdoor ? "Outdoor" : null,
      ];
      const createInput: CreateConceptInput = {
        accountID: data.accountID,
        name: data.name,
        description: data.description ? data.description : "",
        email: data.email ? data.email : "",
        location: data.location ? data.location : "",
        type: data.type ? data.type : "",
        seatTypes: seats ? seats : [],
        areas: areas ? areas : [],
        image: data.image ? data.image : "",
        logo: data.logo ? data.logo : "",
        album: data.album ? data.album : [],
        eventDeposit: data.eventDeposit ? parseInt(data.eventDeposit) : 0,
        merchantID: data.merchantID ? data.merchantID : "",
        merchantUsername: data.merchantUsername ? data.merchantUsername : "",
        merchantPassword: data.merchantPassword ? data.merchantPassword : "",
        merchantIntegrationNumber: data.merchantIntegrationNumber
          ? data.merchantIntegrationNumber
          : "",
        merchantAPIKey: data.merchantAPIKey ? data.merchantAPIKey : "",
        exportInterval: data.exportInterval
          ? parseInt(data.exportInterval)
          : 1000000,
        operating: data.operating ? data.operating : false,
        precedence: parseInt(data.precedence),
        longitude: data.longitude,
        latitude: data.latitude,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };
      console.log({ createInput: createInput });
      await DataStore.save(new Concept(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: ConceptUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });
      const seats = [
        data.seatTypes_Bar ? "Bar" : null,
        data.seatTypes_Table ? "Table" : null,
      ];
      const areas = [
        data.areas_Indoor ? "Indoor" : null,
        data.areas_Outdoor ? "Outdoor" : null,
      ];
      console.log({ originalData: data });
      await DataStore.save(
        Concept.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name : original!.name;
          updated.description = data.description
            ? data.description
            : original!.description;
          updated.email = data.email ? data.email : original!.email;
          updated.location = data.location ? data.location : original!.location;
          updated.type = data.type ? data.type : original!.type;
          updated.seatTypes = seats;
          updated.areas = areas;
          updated.operating = data.operating ? data.operating : false;
          updated.image = data.image ? data.image : original!.image;
          updated.logo = data.logo ? data.logo : original!.logo;
          updated.album = data.album ? data.album : original!.album;
          updated.eventDeposit = data.eventDeposit
            ? parseInt(data.eventDeposit)
            : original!.eventDeposit;
          updated.merchantID = data.merchantID;
          updated.merchantUsername = data.merchantUsername;
          updated.merchantPassword = data.merchantPassword
            ? data.merchantPassword
            : original!.merchantPassword;
          updated.merchantIntegrationNumber = data.merchantIntegrationNumber;
          updated.merchantAPIKey = data.merchantAPIKey;
          updated.exportInterval = parseInt(data.exportInterval);
          updated.precedence = parseInt(data.precedence);
          updated.longitude = data.longitude;
          updated.latitude = data.latitude;
        })
      );
      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ConceptGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Concept.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ConceptBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: ConceptGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Concept) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  function options(listing: Concept[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },

    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = Concept as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Concept[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };

  api[`${listingName}ChangeSelected`] = (conceptID: string) => {
    dispatch(setSelected(conceptID));

    localStorage.setItem(LOCAL_STORAGE.SELECTED_CONCEPT, conceptID);
  };
  api[`${listingName}SetBranches`] = (branches: any) =>
    dispatch(setBranches(branches));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };

  return api;
};

export default useResource;
