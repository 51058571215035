import {
  CreateChoiceGroupsInput,
  UpdateChoiceGroupsInput,
} from "./../models/GQL_API";
import {
  GetVariables,
  ListingByConceptVariables,
  ChoiceGroupsBulkTrashVariables,
  Option,
} from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { ChoiceGroups } from "../models";
import { createChoiceGroups, updateChoiceGroups } from "../graphql/mutations";
import { getChoiceGroups, listChoiceGroups } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import { setListing, setNextToken, setLastIndex, setPagination } from "../store/ducks/choiceGroup";
import { extractSelectedCheckboxes } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showWarning, showError, showConfirm } = useApp();

  const session = useSelector((state: any) => state.app.session);
  const nextToken= useSelector((state: any) => state.choiceGroups.nextToken);
  const lastIndex= useSelector((state: any) => state.choiceGroups.lastIndex);
  const paginationListing = useSelector((state: any) => state.choiceGroups.pagination);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);

  async function fetch(params: ListingByConceptVariables) { 
    try {
      const { conceptID, searchText, limit ,startIndex} = params;
      const filter: any = {
        conceptID: { eq: conceptID?conceptID:selectedConcept },
      };

      // if (searchText.length > 0)
      //   filter.name = { contains: searchText.toLowerCase() };
      if(lastIndex>=startIndex && lastIndex!==null && paginationListing.length>0 && selectedConcept===paginationListing[0].conceptID  )
      {
        if (searchText.length > 0)
        {
          let filteredData=paginationListing.filter((item:any)=>{ return item.name.toLowerCase().includes(searchText.toLowerCase())})
          return filteredData;
        }
        return paginationListing.slice(startIndex,startIndex+limit)
      }

      const listing: any = await API.graphql<GraphQLQuery<ChoiceGroups>>({
        query: listChoiceGroups,
        variables: { filter, limit: limit ?? 1000,nextToken:paginationListing.length>0 &&selectedConcept===paginationListing[0].conceptID ?nextToken:undefined },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      dispatch(setLastIndex(startIndex));
      dispatch(setNextToken(listing.data.listChoiceGroups.nextToken));
      dispatch(setPagination( paginationListing.concat(listing.data.listChoiceGroups.items)));

      if (searchText.length > 0)
      {
        let filteredData=listing.data.listChoiceGroups.items.filter((item:any)=>{ return item.name.toLowerCase().includes(searchText.toLowerCase())})
        return filteredData;
      }

      return listing.data.listChoiceGroups.items;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const choiceGroup: any = await API.graphql({
        query: getChoiceGroups,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return choiceGroup.data.getChoiceGroups;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    try {
      if (!data.name) throw new Error("Name is required");
      const choices: string[] = extractSelectedCheckboxes("choices_", data);

      const createInput: CreateChoiceGroupsInput = {
        conceptID: data.conceptID,
        name: data.name,
        required: data.required !== undefined ? data.required : false,
        maxNumberOfChoices: data.maxNumberOfChoices
          ? parseInt(data.maxNumberOfChoices)
          : 0,
        minNumberOfChoices: data.minNumberOfChoices
          ? parseInt(data.minNumberOfChoices)
          : 0,
        precedence: data.precedence ? data.precedence : "0",
        choices: choices ? choices : [],

        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const ChoiceGroups = await API.graphql<GraphQLQuery<ChoiceGroups>>({
        query: createChoiceGroups,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);

      return ChoiceGroups;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session: any) {
    try {
      const { data } = params;
      let original = await get(params);

      let choices: any = new Set();
      const updatedChoices: string[] = extractSelectedCheckboxes(
        "choices_",
        data
      );

      if (updatedChoices && updatedChoices.length > 0) {
        for (let choice of updatedChoices) {
          choices.add(choice);
        }
        choices = Array.from(choices);
      } else {
        choices = Array.from(original!.choices);
      }
      console.log(choices)

      const updateInput: UpdateChoiceGroupsInput = {
        id: original.id,
        name: data.name ? data.name : original.name,
        required:
          data.required !== undefined ? data.required : original!.required,
        maxNumberOfChoices: data.maxNumberOfChoices
          ? parseInt(data.maxNumberOfChoices)
          : original!.maxNumberOfChoices,
        minNumberOfChoices: data.minNumberOfChoices
          ? parseInt(data.minNumberOfChoices)
          : original!.minNumberOfChoices,
        precedence: data.precedence ? data.precedence : original!.precedence,
        choices: choices ? choices : original!.choices,

        _version: original._version,
      };

      const ChoiceGroups: any = await API.graphql<GraphQLQuery<ChoiceGroups>>({
        query: updateChoiceGroups,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);

      return ChoiceGroups.data.updateOnlineOrder;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      const updateInput: UpdateChoiceGroupsInput = {
        id: original.id,
        deleted: "1",

        _version: original._version,
      };

      await API.graphql<GraphQLQuery<ChoiceGroups>>({
        query: updateChoiceGroups,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ChoiceGroupsBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  function options(listing: ChoiceGroups[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = ChoiceGroups as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;

  api[`${listingName}ChangeListing`] = (listing: ChoiceGroups[]) =>
    dispatch(setListing(listing));

  return api;
};

export default useResource;
