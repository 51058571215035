import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import {
  setDays,
  setListing,
  setSelected,
  setTimeSlots,
} from "../store/ducks/area";
import { Area } from "../models";
import {
  AreaBulkTrashVariables,
  AreaGetVariables,
  AreaListingVariables,
  AreaUpdateVariables,
  Option,
  HeadCell,
  CreateVariables,
} from "../models/app";
import { CreateAreaInput } from "../models/GQL_API";
import useApp from "./useApp";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError } = useApp();

  async function fetch(params: AreaListingVariables) {
    const { searchText, startIndex, limit, conceptID } = params;

    try {
      const listing = await DataStore.query(
        Area as any,
        (area: any) =>
          area
            .conceptID("eq", conceptID)
            .deleted("eq", "0")
            .or((area: any) => area.name("contains", searchText)),
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // if (listing.length > 0) {
      //   dispatch(setListing(listing));

      //   if (selected.length === 0) {
      //     dispatch(setSelected(listing[0].id));

      //     return listing[0].id;
      //   } else {
      //     return selected;
      //   }
      // }

      return listing;
    } catch (err) {
      throw err;
    }
  }

  async function fetchException(params: AreaListingVariables) {
    const {
      startIndex,
      limit,
      searchText,
      conceptID,
      isDefault,
      weekDay,
      timeSlot,
      expiryDate,
    } = params;

    try {
      const listing = await DataStore.query(
        Area as any,
        (area: any) => {
          area.conceptID("eq", conceptID).deleted("eq", "0");

          if (isDefault) area.isDefault("eq", isDefault);
          if (weekDay) area.days("contains", weekDay);
          if (timeSlot) area.timeSlots("contains", timeSlot);
          if (expiryDate) area.expiryDate("ge", expiryDate);

          if (searchText && searchText.length > 0)
            area.or((area: any) => area.name("contains", searchText));

          return area;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      throw err;
    }
  }

  async function get(params: AreaGetVariables) {
    const { id, listing } = params;

    try {
      const single: Area | undefined =
        listing.length === 0
          ? await DataStore.query(Area as any, id)
          : listing.find((model: Area) => model.id === id);

      return single;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.conceptID) {
      const error = new Error("Cannot create area without conceptID ");
      return showError(error);
    }

    try {
      const createInput: CreateAreaInput = {
        conceptID: data.conceptID,
        name: data.name.toLowerCase(),
        isDefault: data.isDefault,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.expiryDate) createInput.expiryDate = data.expiryDate;
      if (data.days) createInput.days = data.days;
      if (data.timeSlots) createInput.timeSlots = data.timeSlots;

      await DataStore.save(new Area(createInput as any));

      return `New ${singleName} has been created successfully`;
    } catch (err) {
      throw err;
    }
  }

  async function update(params: AreaUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ listing, id });

      await DataStore.save(
        Area.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name.toLowerCase() : original!.name;
          updated.isDefault = data.isDefault
            ? data.isDefault
            : original!.isDefault;
          updated.expiryDate = data.expiryDate
            ? data.expiryDate
            : original!.expiryDate;
          updated.days = data.days ? data.days : original!.days;
          updated.timeSlots = data.timeSlots
            ? data.timeSlots
            : original!.timeSlots;
        })
      );

      return `${singleName} has been updated successfully`;
    } catch (err) {
      throw err;
    }
  }

  async function trash(params: AreaGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Area.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      return `${singleName} has been moved to trash successfully`;
    } catch (err) {
      throw err;
    }
  }

  async function bulkTrash(params: AreaBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    return `${ids.size} ${listingName} items has been moved to trash`;
  }

  async function remove(params: AreaGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      return `${singleName} has been deleted successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  function options(listing: Area[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = Area as any;
  api[`${listingName}Options`] = options;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchException`] = fetchException;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Area[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}ChangeDays`] = (days: any) => dispatch(setDays(days));
  api[`${listingName}ChangeTimeSlots`] = (timeSlots: any) =>
    dispatch(setTimeSlots(timeSlots));

  return api;
};

export default useResource;
