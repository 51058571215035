import { extractSelectedCheckboxes } from "./../helpers/utils";
import { API, DataStore, Predicates, SortDirection } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch } from "react-redux";
import { listAccounts } from "../graphql/queries";
import { setListing, setSelected } from "../store/ducks/account";
import { Account } from "../models";
import { CreateAccountInput } from "../models/GQL_API";
import {
  HeadCell,
  Option,
  ListingVariables,
  AccountGetVariables,
  AccountUpdateVariables,
  AccountBulkTrashVariables,
  CreateVariables,
} from "../models/app";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const url = window.location.hostname;
  async function fetch(params: ListingVariables) {
    const { searchText, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        Account as any,
        (acc: any) =>
          acc
            .deleted("eq", "0")
            .or((acc: any) =>
              acc
                .domain("contains", searchText)
                .siteTitle("contains", searchText)
                .siteAddress("contains", searchText)
            ),
        {
          page: startIndex,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function byDomainOffline() {
    try {
      const listing = await DataStore.query(Account as any, Predicates.ALL, {
        page: 0,
        limit: 100,
        sort: (s) => s.createdAt(SortDirection.DESCENDING),
      });

      const account = listing.find((acc: any) => acc.siteAddress.contains(url));

      if (account) {
        dispatch(setSelected(account));

        return account;
      }
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function byDomainOnline(isAuth: boolean) {
    try {
      const listing: any = await API.graphql({
        query: listAccounts,
        variables: {
          limit: 1000,
          filter: {
            siteAddress: { contains: url },
          },
        },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const account = listing.data.listAccounts.items[0];

      if (account) {
        dispatch(setSelected(account));

        return account;
      }
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function get(params: AccountGetVariables) {
    const { id, listing } = params;
    try {
      const single: Account | undefined = await DataStore.query(
        Account as any,
        id
      );
      return single;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    const languages: string[] = extractSelectedCheckboxes("languages_", data);
    const features: string[] = extractSelectedCheckboxes("features_", data);
    let dynamicStatus = JSON.stringify([
      { name: "pending", accessibleStates: ["canceled", "confirmed"] },
      { name: "confirmed", accessibleStates: [] },
      { name: "canceled", accessibleStates: [] },
    ]);

    try {
      const createInput: CreateAccountInput = {
        domain: data.domain,
        siteTitle: data.siteTitle,
        tagline: data.tagline,
        description: data.description,
        siteAddress: data.siteAddress,
        defaultLanguage: data.defaultLanguage,
        status: "active",
        languages: languages,
        features: features,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
        kpiReportResetFrequency: data.kpiReportResetFrequency,
        agentBarResetFrequency: data.agentBarResetFrequency,
        dynamicStatus: dynamicStatus,
      };

      /**
       * @toDO: Add account social media links
       */

      await DataStore.save(new Account(createInput as any));

      return `New ${singleName} has been created successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function update(params: AccountUpdateVariables) {
    const { id, listing, data } = params;
    console.log("updating Acc", data);

    let languages: string[];
    let features: string[];
    if (!data.dynamicStatus) {
      languages = extractSelectedCheckboxes("languages_", data);
      features = extractSelectedCheckboxes("features_", data);
    }
    try {
      const original = await get({ id, listing });
      await DataStore.save(
        Account.copyOf(original!, (updated) => {
          // updated.domain = data.domain ? data.domain : original!.domain;
          updated.description = data.description
            ? data.description
            : original!.description;
          updated.siteAddress = data.siteAddress
            ? data.siteAddress
            : original!.siteAddress;
          updated.defaultLanguage = data.defaultLanguage
            ? data.defaultLanguage
            : original!.defaultLanguage;
          updated.languages = languages ? languages : original!.languages;
          updated.features =
            features && features.length > 0 ? features : original!.features;
          updated.kpiReportResetFrequency = data.kpiReportResetFrequency
            ? data.kpiReportResetFrequency
            : original!.kpiReportResetFrequency;
          updated.agentBarResetFrequency = data.agentBarResetFrequency
            ? data.agentBarResetFrequency
            : original!.agentBarResetFrequency;
          updated.dynamicStatus = data.dynamicStatus;
          updated.sponsorImage = data.sponsorImage
            ? data.sponsorImage
            : original!.sponsorImage;
        })
      );

      return `${singleName} has been updated successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function trash(params: AccountGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Account.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      return `${singleName} has been moved to trash successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function bulkTrash(params: AccountBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    return `${ids.size} ${listingName} items has been moved to trash`;
  }

  async function remove(params: AccountGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      return `${singleName} has been deleted successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  function options(listing: Account[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.siteTitle, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "siteTitle",
      numeric: false,
      disablePadding: false,
      label: "Site Title",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["siteTitle"];

  const api: any = {};

  api[`${listingName}Model`] = Account as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByDomain`] = byDomainOffline;
  api[`${listingName}FetchByDomainOnline`] = byDomainOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Account[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
