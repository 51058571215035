import { useEffect, useState } from "react";
import useAttachment from "../../../hooks/useAttachment";
import useLoading from "../../../hooks/useLoading";
import ContentLoader from "../ContentLoader";

import FeaturedImage from "../FeaturedImage";
import { Pages } from "../../../constants/enums";

interface Props {
  name: string;
  label: string;
  value: any;
  setValue: any;
  register: any;
  resource?:any;
  slug?:any;
}

const ImageField: React.FC<Props> = ({
  name,
  label,
  value,
  setValue,
  register,
  resource,
  slug,
}) => {
  const [state, setState] = useState(value);
  const { loading, changeLoading } = useLoading();
  const { attachmentsGet } = useAttachment("attachments", "attachment");

  async function fetchAttachment() {
    if (typeof value === "string" && value.length > 0 && slug!==Pages.MENU_ITEMS && slug!==Pages.CATEGORIES) {
      const attachment = await attachmentsGet({ id: value });
      setState(attachment);
    }
    else if(typeof value === "string" && value.length > 0)
    {
      setState(value);
    }

    changeLoading(false);
  }

  useEffect(() => {
    register(name);
    fetchAttachment();

    // eslint-disable-next-line
  }, []);

  return loading ? (
    <ContentLoader />
  ) : (
    <FeaturedImage
      label={label}
      attachment={state}
      resource={resource}
      slug={slug}
      changeAttachment={(attachment: any) => {
        setValue(name, attachment);
        setState(attachment);
      }}
    />
  );
};

export default ImageField;
