import { getPlanItem } from "./../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLQuery } from "@aws-amplify/api";
import {
  CreateVariables,
  PlanItemBulkTrashVariables,
  PlanItemUpdateVariables,
  Option,
  ListingVariables,
} from "./../models/app";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import {
  setListing,
  setSaveChanges,
  setSelected,
} from "../store/ducks/planItem";
import { HeadCell } from "../models/dataTable";
import { PlanItem } from "../models";
import useApp from "./useApp";
import {
  GetVariables,
  PlanItemGetVariables,
  PlanItemListingVariables,
} from "../models/app";
import { CreatePlanItemInput } from "../models/GQL_API";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: PlanItemListingVariables) {
    const { areaID, searchText, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        PlanItem as any,
        (model: any) => {
          model.areaID("eq", areaID).deleted("eq", "0");

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));
      return listing;
    } catch (err: Error | any) {
      // console.log(err);
      showError(err.message);
    }
  }

  async function fetchAll(params: ListingVariables) {
    const { startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        PlanItem as any,
        (model: any) => {
          // Test Concept default floor 6a92e078-a8e3-4da2-806b-bf115cf8917b
          model
            .deleted("eq", "0")
            .areaID("eq", "6a92e078-a8e3-4da2-806b-bf115cf8917b");

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: PlanItem | undefined = await DataStore.query(
        PlanItem as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(id: string, session = false) {
    try {
      const planItem: any = await API.graphql<GraphQLQuery<PlanItem>>({
        query: getPlanItem,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return planItem.data?.getPlanItem;
    } catch (err) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getNames = (params: PlanItemGetVariables) => {
    const { listing, tables } = params;

    if (!tables) {
      const error = new Error("Cannot get plan item names without tables");
      return showError(error);
    }

    let tableName = "";

    if (tables.size > 0) {
      tables.forEach((table: string) => {
        const model = listing.find((model: PlanItem) => model.id === table);

        if (model) tableName += model.name + "-";
      });

      return tableName.slice(0, -1);
    }

    return "";
  };

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.listing) {
      const error = new Error("Old plan items cannot be empty");
      return showError(error);
    }

    try {
      const createInput: CreatePlanItemInput = {
        areaID: data.areaID,
        xPosition: data.xPosition,
        yPosition: data.yPosition,
        name: data.name,
        lockDays: [],
        isReserved: false,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.table) createInput.table = data.table;
      if (data.object) createInput.object = data.object;
      if (data.bookings) createInput.bookings = data.bookings;

      const model = await DataStore.save(new PlanItem(createInput as any));
      dispatch(setListing([...data.listing, model]));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: PlanItemUpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        PlanItem.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name : original!.name;
          updated.xPosition = data.xPosition
            ? data.xPosition
            : original!.xPosition;
          updated.yPosition = data.yPosition
            ? data.yPosition
            : original!.yPosition;
          updated.lockDays =
            data.lockDays !== undefined ? data.lockDays : original!.lockDays;
          updated.isReserved = data.isReserved
            ? data.isReserved
            : original!.isReserved;
          updated.bookings = data.bookings ? data.bookings : original!.bookings;
        })
      );

      // showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        PlanItem.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: PlanItemBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: PlanItemGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: PlanItem[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = PlanItem as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetNames`] = getNames;
  api[`${listingName}ChangeListing`] = (listing: PlanItem[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}ChangeSaveChanges`] = (flag: boolean) =>
    dispatch(setSaveChanges(flag));

  return api;
};

export default useResource;
