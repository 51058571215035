import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";

import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSelected,
  setSelectedFilters,
} from "../store/ducks/condition";
import { Concept, MobileNotification } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import {
  CreateMobileNotificationInput,
  UpdateMobileNotificationInput,
} from "../models/GQL_API";
import {
  ConceptBulkTrashVariables,
  ConceptUpdateVariables,
  CreateVariables,
  ConceptGetVariables,
  ConceptListingVariables,
  Option,
} from "../models/app";
import { getMobileNotification } from "../graphql/queries";
import { listMobileNotifications } from "./../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import CreateNotificationFiltersService from "../services/createNotificationFiltersService";
import {
  createMobileNotification,
  deleteMobileNotification,
  updateMobileNotification,
} from "../graphql/mutations";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const session = useSelector((state: any) => state.app.session);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);

  const headCells: readonly HeadCell[] = [
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
    },
    {
      id: "image",
      numeric: false,
      disablePadding: false,
      label: "Image",
    },
    {
      id: "default",
      numeric: false,
      disablePadding: false,
      label: "Default",
    },
    {
      id: "event",
      numeric: false,
      disablePadding: false,
      label: "Event",
    },
    {
      id: "eventDate",
      numeric: false,
      disablePadding: false,
      label: "Event Date",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];
  const dataCells: readonly string[] = [
    "title",
    "image",
    "default",
    "event",
    "eventDate",
  ];

  async function fetch(params: ConceptListingVariables) {
    const { startIndex, limit } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      // Selected Concept Filter
      if (selectedConcept) {
        filter.conceptID = { eq: selectedConcept };
      }

      const listing: any = await API.graphql({
        query: listMobileNotifications,
        variables: { filter, limit },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const notifications = listing.data.listMobileNotifications.items;

      return notifications;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: ConceptGetVariables) {
    const { id, listing } = params;

    try {
      // Check state first
      if (listing.length > 0) {
        const single: MobileNotification | undefined = listing.find(
          (model: any) => model.id === id
        );
        return single;
      }

      // Check DB
      const notification: any = await API.graphql({
        query: getMobileNotification,
        variables: { id: id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return notification.data.getMobileNotification;
    } catch (err) {
      showError(err);
    }
  }

  function formatDate(date: any) {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Reformat the date string to match the desired format
    const parts = formattedDate.split("/");
    return `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(
      2,
      "0"
    )}`;
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    console.log({ data: data });

    // Validation
    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateMobileNotificationInput = {
        title: data.title ? data.title : "",
        description: data.description ? data.description : "",
        body: data.body ? data.body : "",
        default: data.default,
        event: data.event,
        image: data.image ? data.image : "",
        concepts: data.concepts ? data.concepts : [],
        condition: data.conditions ? data.conditions : [],
        startDate: data.startDate ? data.startDate : "",
        endDate: data.endDate ? data.endDate : "",

        eventDate: data.eventDate ? data.eventDate : "",
        conceptID: data.conceptID ? data.conceptID : "",
        depositValue: data.depositValue ? data.depositValue : "",

        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const notification: any = await API.graphql<MobileNotification>({
        query: createMobileNotification,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const FiltersData = {
        concepts: data.concepts ? data.concepts : "",
        condition:
          data.conditions && data.conditions.length === 1
            ? data.conditions[0]
            : "",
        startDate: data.startDate ? formatDate(data.startDate) : "",
        endDate: data.endDate ? formatDate(data.endDate) : "",
        notificationID: notification.data.createMobileNotification.id,
        notification: {
          title: "Baky Hospitality",
          body: notification.data.createMobileNotification.description
            ? notification.data.createMobileNotification.description
            : "",
          page: "notification",
        },
      };
      await CreateNotificationFiltersService(FiltersData);

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: ConceptUpdateVariables) {
    const { id, listing, data } = params;
    console.log({ data: data });
    try {
      const original = await get({ id, listing });

      const updateInput: UpdateMobileNotificationInput = {
        id: original.id,
        title: data.title ? data.title : original!.title,
        body: data.body ? data.body : original!.body,
        image: data.image ? data.image : original!.image,
        group: data.group ? data.group : original!.group,
        description: data.description
          ? data.description
          : original!.description,
        default: data.default,
        event: data.event, // Featured Banner
        concepts: data.concepts ? data.concepts : original!.concepts,
        condition: data.condition ? data.condition : original!.condition,
        startDate: data.startDate ? data.startDate : original!.startDate,
        endDate: data.endDate ? data.endDate : original!.endDate,

        eventDate: data.eventDate ? data.eventDate : original!.eventDate,
        conceptID: data.conceptID ? data.conceptID : original!.conceptID,
        depositValue: data.depositValue
          ? data.depositValue
          : original!.depositValue,
        _version: original._version,
      };

      const Category: any = await API.graphql<GraphQLQuery<MobileNotification>>(
        {
          query: updateMobileNotification,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        }
      );

      showConfirm(`${singleName} has been updated successfully`);
      return Category;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ConceptGetVariables) {
    try {
      const { id, listing } = params;
      const original = await get(params);

      const updateInput: UpdateMobileNotificationInput = {
        id: original.id,
        deleted: "1",
        _version: original._version,
      };

      await API.graphql<GraphQLQuery<MobileNotification>>({
        query: updateMobileNotification,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been moved to trash successfully`);

      // Update List
      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ConceptBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      await API.graphql<MobileNotification>({
        query: deleteMobileNotification,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: ConceptGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Concept) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  function options(listing: Concept[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const api: any = {};

  api[`${listingName}Model`] = Concept as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;

  api[`${listingName}ChangeSelected`] = (conceptID: string) => {
    dispatch(setSelected(conceptID));
  };
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));

  return api;
};

export default useResource;
