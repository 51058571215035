import axios from "axios";

async function CreateNotificationFiltersService(data: any) {
  try {
    // Lambda Function: hospitalityofflineNotificationFilters-bakyprod
    const response = await axios.post(
      "https://lzidpqhuzgfpzyj7lbbdxj7nrm0bozbc.lambda-url.us-east-2.on.aws/",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error creating filters:", error);
    throw error;
  }
}

export default CreateNotificationFiltersService;
