import { ADMIN_ROLES } from "../constants/enums";
import { AdminPermissions, AdminRoleGetVariables } from "../models/app";
import useAdminRole from "./useAdminRole";

const usePermissions = () => {
  const { adminRolesGet } = useAdminRole("adminRoles", "adminRole");

  const adminPermissions: AdminPermissions = {
    admins: false,
    dashboard: false,
    settings: false,
    viewReservations: false,
    createReservation: false,
    cancelReservation: false,
    createGuest: false,
    editGuest: false,
    editVip: false,
    lockTable: false,
    editLayout: false,
    transactionReport: false,
    paymentDashboard: false,
    refundPayment: false,
    paymobSettings: false,
    callCenter: false,
    waiter:false,
    kitchen:false,
  };

  const getAdminPermissions = async (props: {
    userGroup: any;
  }): Promise<AdminPermissions> => {
    const { userGroup } = props;
    if (userGroup && userGroup.length > 0) {
      let userRoles: any[] = [];
      for (let group of userGroup) {
        userRoles = userRoles.concat(group.roles);
      }

      // const userRoles = userGroup[0].roles;
      for (let role of userRoles) {
        const adminRolesParams: AdminRoleGetVariables = {
          id: role,
          listing: [],
        };

        const userRole = await adminRolesGet(adminRolesParams);
        switch (userRole.name) {
          case ADMIN_ROLES.ADMINS:
            adminPermissions.admins = true;
            break;
          case ADMIN_ROLES.DASHBOARD:
            adminPermissions.dashboard = true;
            break;
          case ADMIN_ROLES.SETTINGS:
            adminPermissions.settings = true;
            break;
          case ADMIN_ROLES.VIEW_RESERVATIONS:
            adminPermissions.viewReservations = true;
            break;
          case ADMIN_ROLES.CREATE_RESERVATION:
            adminPermissions.createReservation = true;
            break;
          case ADMIN_ROLES.CANCEL_RESERVATION:
            adminPermissions.cancelReservation = true;
            break;
          case ADMIN_ROLES.CREATE_GUEST:
            adminPermissions.createGuest = true;
            break;
          case ADMIN_ROLES.EDIT_GUEST:
            adminPermissions.editGuest = true;
            break;
          case ADMIN_ROLES.EDIT_VIP:
            adminPermissions.editVip = true;
            break;
          case ADMIN_ROLES.EDIT_LAYOUT:
            adminPermissions.editLayout = true;
            break;
          case ADMIN_ROLES.LOCK_TABLE:
            adminPermissions.lockTable = true;
            break;
          case ADMIN_ROLES.TRANSACTION_REPORT:
            adminPermissions.transactionReport = true;
            break;
          case ADMIN_ROLES.PAYMENT_DASHBOARD:
            adminPermissions.paymentDashboard = true;
            break;
          case ADMIN_ROLES.REFUND_PAYMENT:
            adminPermissions.refundPayment = true;
            break;
          case ADMIN_ROLES.PAYMOB_SETTINGS:
            adminPermissions.paymobSettings = true;
            break;
          case ADMIN_ROLES.CALL_CENTER:
            adminPermissions.callCenter = true;
            break;
          case ADMIN_ROLES.WAITER:
            adminPermissions.waiter = true;
              break;
          case ADMIN_ROLES.KITCHEN:
            adminPermissions.kitchen = true;
              break;
          default:
            break;
        }
      }
    }

    return adminPermissions;
  };

  return { getAdminPermissions };
};

export default usePermissions;
