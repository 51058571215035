// actions
const SET = "features/SET";
const SET_ALL = "features/SET_ALL";
const SET_SELECTED = "features/SET_SELECTED";

const DEFAULT_STATE = {
  listing: [],
  listingAll: [],
  selected: "",
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_ALL:
      return Object.assign({}, state, { listingAll: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, {
        selected: action.concept,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setListingAll = (listing: any[]) => ({ listing, type: SET_ALL });
export const setSelected = (single: any) => ({ single, type: SET_SELECTED });
