import StepFunctions from "aws-sdk/clients/stepfunctions";
import { Auth } from "aws-amplify";

const scheduleEmailSend = async (params: ScheduleEmailSendParams) => {
  try {
    const allowed = await checkAdminGroup();

    if (!allowed) {
      throw new Error("You are not allowed to perform this action");
    }

    const stepfunctions = new StepFunctions({
      region: "us-east-2",
      //   credentials: {
      //     accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID!,
      //     secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY!,
      //   },
      credentials: await Auth.currentCredentials(),
    });
    const stateMachineArn = process.env.REACT_APP_STATEMACHINE_ARN;

    const result = await stepfunctions
      .startExecution({
        stateMachineArn: stateMachineArn!,
        input: JSON.stringify({
          ...params,
          //making sure that optional params are not undefined
          reservationDate: params.reservationDate || "",
          reservationTime: params.reservationTime || "",
          numberOfGuests: params.numberOfGuests || "",
          bookingLink: params.bookingLink || "",
        } as ScheduleEmailSendParams),
      })
      .promise();
    console.log("result", result);
    console.log(
      "Scheduled email to be sent at",
      params.sendEmailAtISO,
      "for",
      params.customerName,
      "at",
      params.customerEmail,
      "using template",
      params.templateName
    );

    return result;
  } catch (err) {
    console.error("Error scheduling email send:", err);
    throw err;
  }
};
export default scheduleEmailSend;
interface ScheduleEmailSendParams {
  sendEmailAtISO: string; // date in ISO format
  customerName: string;
  customerEmail: string;
  templateName: string;
  sourceEmail: string;
  //the following are required for some templates
  reservationDate?: string;
  reservationTime?: string;
  numberOfGuests?: string;
  bookingLink?: string;
  //To add more fields, remember to add them in step function too
  //As the emailing lambda uses query strings and not the request body,
  //these params are transformed into query strings in the step function
}

async function checkAdminGroup() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    return groups && groups.includes("admin");
  } catch (error) {
    console.error("Error checking user groups:", error);
    return false;
  }
}
