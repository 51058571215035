import {
  CreateVariables,
  TimeSlotBulkTrashVariables,
  TimeSlotUpdateVariables,
  Option,
} from "./../models/app";
import { DataStore, SortDirection, API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveIndex,
  setListing,
  setSelected,
  setListingAll,
} from "../store/ducks/timeSlot";
import { HeadCell } from "../models/dataTable";
import { TimeSlot } from "../models";
import useApp from "./useApp";
import { TimeSlotGetVariables, TimeSlotListingVariables } from "../models/app";
import { CreateTimeSlotInput, ModelSortDirection } from "../models/GQL_API";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLQuery } from "@aws-amplify/api";
import { getTimeSlot, listTimeSlots } from "../graphql/queries";

const useResource = (listingName: string, singleName: string) => {
  const session = useSelector((state: any) => state.app.session);

  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: TimeSlotListingVariables) {
    const { conceptID, startIndex, limit, searchText } = params;

    try {
      let listing: TimeSlot[] = [];
      if (conceptID) {
        const filter: any = {
          deleted: { eq: "0" },
        };

        if (conceptID) filter.conceptID = { eq: conceptID };
        else return []; // List slots per selected concept only

        if (searchText.length > 0) {
          filter.name = { contains: searchText.toLowerCase() };
        }

        const listData: any = await API.graphql({
          query: listTimeSlots,
          variables: {
            filter,
            limit: limit || 1000,
          },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        listing = listData.data.listTimeSlots.items;

        // Sort them using updatedAt (from  old to new)
        if (listing.length > 0) {
          let orderedList = listing.sort((a: any, b: any) => {
            return b.updatedAt < a.updatedAt ? 1 : -1;
          });

          return orderedList;
        }
      } else {
        listing = await DataStore.query(
          TimeSlot as any,
          (model: any) => {
            model.deleted("eq", "0");

            if (conceptID) model.conceptID("eq", conceptID);

            if (searchText.length > 0)
              model.name("contains", searchText.toLowerCase());

            return model;
          },
          {
            page: startIndex / limit,
            limit: limit,
            sort: (s) => s.updatedAt(SortDirection.DESCENDING),
          }
        );
      }
      return listing;
      // return activateTimeSlot(listing);
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  /**
   * Activate current time slot
   *
   * @param timeSlots timeSlots: TimeSlot[]
   * @returns void
   */
  // const activateTimeSlot = async (timeSlots: TimeSlot[]) => {
  //   try {
  //     for (let i = 0; i < timeSlots.length; i++) {
  //       const startTime: string = timeSlots[i].name.split(" - ")[0];
  //       let startHours: number;

  //       startHours = startTime.includes("AM")
  //         ? hoursStringToNumber(startTime, "AM")
  //         : hoursStringToNumber(startTime, "PM");

  //       const startToCurrent: number = compareHoursToCurrent(startHours);

  //       if (startToCurrent === 0 || startToCurrent === -1) {
  //         dispatch(setActiveIndex(i));
  //         dispatch(setSelected(timeSlots[i].id));

  //         return timeSlots[i].id;
  //       } else {
  //         dispatch(setSelected(timeSlots[activeIndex].id));

  //         return timeSlots[activeIndex].id;
  //       }
  //     }
  //   } catch (err: Error | any) {
  //     showError(`${err.message}`);
  //   }
  // };

  /**
   * Get Resource Name
   *
   * @param set set: set(<string>)
   *
   * @returns string
   */
  const getNames = (params: TimeSlotGetVariables) => {
    const { listing, timeSlots } = params;

    let listingName = "";

    if (timeSlots && timeSlots.size > 0) {
      timeSlots.forEach((id: string) => {
        const model = listing.find((model: TimeSlot) => model.id === id);

        if (model) {
          if (listingName.length > 0) {
            listingName += "," + model.friendlyName;
          } else {
            listingName += model.friendlyName;
          }
        }
      });
    }

    return listingName;
  };

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: TimeSlotGetVariables) => {
    const { id, listing, listingAll } = params;

    if (listingAll !== undefined && listingAll.length > 0) {
      const model = listingAll.find((model: TimeSlot) => model.id === id);

      return model ? model.friendlyName : "";
    } else if (listing.length > 0) {
      const model = listing.find((model: TimeSlot) => model.id === id);

      return model ? model.friendlyName : "";
    }

    return "";
  };

  async function get(params: TimeSlotGetVariables) {
    const { id, listing } = params;

    try {
      // const single: TimeSlot | undefined =
      //   listing.length === 0
      //     ? await DataStore.query(TimeSlot as any, id)
      //     : listing.find((model: TimeSlot) => model.id === id);

      // return single;
      
      let single: TimeSlot | undefined;

      if (listing.length === 0) {
        single = await DataStore.query(TimeSlot, id);
      } else {
        single = listing.find((model: TimeSlot) => model.id === id);
        if (single) {
          single = await DataStore.query(TimeSlot, single.id);
        }
      }
  
      console.log({ single });
  
      return single;
    } catch (err) {
      showError(err);
    }
  }
  async function ListAll() {
    try {
      const listing: TimeSlot[] = await DataStore.query(
        TimeSlot as any,
        (model: any) => {
          model.deleted("eq", "0");

          return model;
        },
        {
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));
      // Default selected timeSlot to All
      // setSelected("");
      return listing;
      // return activateTimeSlot(listing);
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateTimeSlotInput = {
        conceptID: data.conceptID,
        name: data.name,
        friendlyName: data.friendlyName ? data.friendlyName : data.name,
        bookings: [],
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      await DataStore.save(new TimeSlot(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: TimeSlotUpdateVariables) {
    const { id, listing, listingAll, data } = params;

    try {
      const original = await get({ id, listing });
      console.log({ original: original });

      // await DataStore.save(
      //   TimeSlot.copyOf(original!, (updated) => {
      //     updated.name = data.name ? data.name : original!.name;
      //     updated.friendlyName = data.friendlyName
      //       ? data.friendlyName
      //       : original!.friendlyName;
      //     updated.bookings = data.bookings ? data.bookings : original!.bookings;
      //   })
      // );
      if (!original) {
        throw new Error('Original model not found');
      }
      await DataStore.save(
        TimeSlot.copyOf(original, (updated) => {
          updated.name = data.name ?? original.name;
          updated.friendlyName = data.friendlyName ?? original.friendlyName;
          updated.bookings = data.bookings ?? original.bookings;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: TimeSlotGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        TimeSlot.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: TimeSlotBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: TimeSlotGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      // console.log(err);
      showError(err);
    }
  }

  async function getOnline(id: string, session = null) {
    try {
      const timeSlot: any = await API.graphql<GraphQLQuery<TimeSlot>>({
        query: getTimeSlot,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return timeSlot.data?.getTimeSlot;
    } catch (err) {
      showError(err);
    }
  }

  function options(listing: TimeSlot[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "friendlyName",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["friendlyName"];

  const api: any = {};

  api[`${listingName}Model`] = TimeSlot as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}GetNames`] = getNames;
  api[`${listingName}ListAll`] = ListAll;
  api[`${listingName}ChangeListing`] = (listing: TimeSlot[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeListingAll`] = (listing: TimeSlot[]) =>
    dispatch(setListingAll(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ActivateIndex`] = (index: number) =>
    dispatch(setActiveIndex(index));

  return api;
};

export default useResource;
