import { CreateChoiceInput, UpdateChoiceInput } from "./../models/GQL_API";
import {
  GetVariables,
  ListingByConceptVariables,
  ChoiceBulkTrashVariables,
  Option,
} from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { Choice } from "../models";
import { createChoice, updateChoice } from "../graphql/mutations";
import { getChoice, listChoices } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import { setListing, setNextToken, setLastIndex, setPagination } from "../store/ducks/choice";


const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();

  const session = useSelector((state: any) => state.app.session);
  const nextToken= useSelector((state: any) => state.choices.nextToken);
  const lastIndex= useSelector((state: any) => state.choices.lastIndex);
  const paginationListing = useSelector((state: any) => state.choices.pagination);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);

  async function fetch(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit ,startIndex} = params;
      const filter: any = {
        conceptID: { eq: conceptID?conceptID:selectedConcept },
      };

      // if (searchText.length > 0)
      //   filter.name = searchText.toLowerCase() ;
      
      if(lastIndex>=startIndex && lastIndex!==null && paginationListing.length>0 && selectedConcept===paginationListing[0].conceptID  )
      {
        if (searchText.length > 0)
        { 
          let filteredData=paginationListing.filter((item:any)=>{ return item.name.toLowerCase().includes(searchText.toLowerCase())})
          return filteredData;
        }
        return paginationListing.slice(startIndex,startIndex+limit)
      }
      const listing: any = await API.graphql<GraphQLQuery<Choice>>({
        query: listChoices,
        variables: { filter, limit: limit ?? 1000 ,nextToken:paginationListing.length>0 &&selectedConcept===paginationListing[0].conceptID ?nextToken:null},
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(setLastIndex(startIndex));
      dispatch(setNextToken(listing.data.listChoices.nextToken));
      dispatch(setPagination( paginationListing.concat(listing.data.listChoices.items)));

      if (searchText.length > 0)
      {
        let filteredData=listing.data.listChoices.items.filter((item:any)=>{ return item.name.toLowerCase().includes(searchText.toLowerCase())})
        return filteredData;
      } 

      return listing.data.listChoices.items;
    } catch (err) {
      showError(err);
    }
  }
  async function fetchByChoiceGroups(params: any) {
    try {
      const { conceptID, ChoiceGroups, limit } = params;
      const filter: any = {
        conceptID: { eq: conceptID },
      };

    
      if(ChoiceGroups.length>0)
      {  filter.or=[];
        for(let i=0;i<ChoiceGroups.length;i++)
        {
          filter.or.push({ choiceGroupID: { eq: ChoiceGroups[i].id } });
        }
  
      }
     
      const listing: any = await API.graphql<GraphQLQuery<Choice>>({
        query: listChoices,
        variables: { filter, limit: limit ?? 1000 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.listChoices.items;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const menuItem: any = await API.graphql({
        query: getChoice,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return menuItem.data.getChoice;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    try {
      const createInput: CreateChoiceInput = {
        name: data.name,
        price: data.price ? Number(data.price) : 0,
        conceptID: data.conceptID,
        choiceGroupID: data.choiceGroupID,
        symphonyID: data.symphonyID ? data.symphonyID : "0",

        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const Choice = await API.graphql<GraphQLQuery<Choice>>({
        query: createChoice,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);

      return Choice;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session: any) {
    try {
      const { data } = params;
      let original = await get(params);

      const updateInput: UpdateChoiceInput = {
        id: original.id,
        name: data.name ? data.name : original.name,
        price: data.price ? data.price : original.price,
        choiceGroupID: data.choiceGroupID
          ? data.choiceGroupID
          : original.choiceGroupID,
        symphonyID: data.symphonyID ? data.symphonyID : original.symphonyID,
        _version: original._version,
      };

      const Choice: any = await API.graphql<GraphQLQuery<Choice>>({
        query: updateChoice,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);
      return Choice.data.updateChoice;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      const updateInput: UpdateChoiceInput = {
        id: original.id,
        deleted: "1",

        _version: original._version,
      };

      await API.graphql<GraphQLQuery<Choice>>({
        query: updateChoice,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ChoiceBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  function options(listing: Choice[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "Price",
    },
    {
      id: "symphonyID",
      numeric: false,
      disablePadding: false,
      label: "simphony ID",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];
  const dataCells: readonly string[] = ["name", "price", "symphonyID"];

  const api: any = {};

  api[`${listingName}Model`] = Choice as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByChoiceGroups`] = fetchByChoiceGroups;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;

  api[`${listingName}ChangeListing`] = (listing: Choice[]) =>
    dispatch(setListing(listing));

  return api;
};

export default useResource;
