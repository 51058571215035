// actions
const SET = "choiceGroups/SET";
const SET_SEARCH_TEXT = "choiceGroups/SET_SEARCH_TEXT";
const SET_SELECTED = "choiceGroups/SET_SELECTED";
const SET_LAST_INDEX = "choiceGroups/SET_LAST_INDEX";
const SET_NEXT_TOKEN = "choiceGroups/SET_NEXT_TOKEN";
const SET_PAGINATION = "choiceGroups/SET_PAGINATION";

const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: "",
  pagination:[],
  nextToken:null,
  lastIndex:null
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_PAGINATION:
      return Object.assign({}, state, { pagination: action.pagination });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    case SET_LAST_INDEX:
      return Object.assign({}, state, { lastIndex: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});
export const setPagination = (pagination: any[]) => ({ pagination, type: SET_PAGINATION });
export const setNextToken = (id: string) => ({ id, type: SET_NEXT_TOKEN });
export const setLastIndex = (id: number) => ({ id, type: SET_LAST_INDEX });

