// actions
const SET = "accounts/SET";
const SET_SELECTED = "accounts/SET_SELECTED";

const DEFAULT_STATE = {
  listing: [],
  selected: null,
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.model });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (model: any) => ({ model, type: SET_SELECTED });
