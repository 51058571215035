// actions
const SET = "onlineOrders/SET";
const SET_LAST_INDEX = "onlineOrders/SET_LAST_INDEX";
const SET_NEXT_TOKEN = "onlineOrders/SET_NEXT_TOKEN";
const SET_PAGINATION = "onlineOrders/SET_PAGINATION";
const SET_FILTER = "onlineOrders/SET_FILTER";


const DEFAULT_STATE = {
  listing: [],
  pagination:[],
  nextToken:null,
  lastIndex:null,
  filter:[]
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_PAGINATION:
      return Object.assign({}, state, { pagination: action.pagination });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    case SET_LAST_INDEX:
      return Object.assign({}, state, { lastIndex: action.id });
    case SET_FILTER:
      return Object.assign({}, state, { filter: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setPagination = (pagination: any[]) => ({ pagination, type: SET_PAGINATION });
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
export const setLastIndex = (id: number) => ({ id, type: SET_LAST_INDEX });
export const setFilter = (id: any) => ({ id, type: SET_FILTER });

