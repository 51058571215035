import {
  CreateVariables,
  TableBulkTrashVariables,
  TableUpdateVariables,
} from "./../models/app";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/table";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { Table } from "../models";
import {
  TableGetVariables,
  TableListingVariables,
  Option,
} from "../models/app";
import { CreateTableInput } from "../models/GQL_API";
import { GraphQLQuery } from "@aws-amplify/api";
import { getTable } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: TableListingVariables) {
    const { conceptID, searchText, startIndex, limit, name } = params;

    try {
      const listing = await DataStore.query(
        Table as any,
        (model: any) => {
          model.conceptID("eq", conceptID).deleted("eq", "0");

          if (name) model.name("eq", name);

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // if (listing.length > 0) {
      //   if (selected.length === 0) dispatch(setSelected(listing[0].id));
      // }
      // dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  const getName = (params: TableGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Table) => model.id === id);
      return model ? model.name : "";
    }

    return "";
  };

  async function get(params: TableGetVariables) {
    const { id, listing } = params;

    try {
      const single: Table | undefined =
        listing.length === 0
          ? await DataStore.query(Table as any, id)
          : listing.find((model: Table) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      if (data.name) {
        const params: TableListingVariables = {
          conceptID: data.conceptID,
          searchText: "",
          startIndex: 0,
          limit: 1000,
          name: data.name,
        };

        const tables = await fetch(params);

        if (tables!.length > 0) {
          const error = new Error("Table already exists");
          return showError(error);
        }
      }

      const createInput: CreateTableInput = {
        conceptID: data.conceptID,
        label: data.label,
        name: data.name.toLowerCase(),
        width: parseInt(data.width),
        height: parseInt(data.height),
        image: data.image
          ? data.image.fileUrl
            ? data.image.fileUrl
            : data.image
          : "",
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.capacity) createInput.capacity = parseInt(data.capacity);

      await DataStore.save(new Table(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function update(params: TableUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      await DataStore.save(
        Table.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name.toLowerCase() : original!.name;
          updated.label = data.label ? data.label : original!.label;
          updated.width = data.width ? parseInt(data.width) : original!.width;
          updated.height = data.height
            ? parseInt(data.height)
            : original!.height;
          updated.capacity = data.capacity
            ? parseInt(data.capacity)
            : original!.capacity;
          updated.image = data.image
            ? data.image.fileUrl
              ? data.image.fileUrl
              : data.image
            : original!.image;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: TableGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Table.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: TableBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: TableGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Table[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  async function getOnline(id: string, session = false) {
    try {
      const table: any = await API.graphql<GraphQLQuery<Table>>({
        query: getTable,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return table.data?.getTable;
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "label",
      numeric: false,
      disablePadding: false,
      label: "Label",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "label"];

  const api: any = {};

  api[`${listingName}Model`] = Table as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Table[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
