import axios from "axios";

const BASE_URL = "https://sms.anyware.software/cequens-sms/";

export const sendOtpViaSMS = async (
  recipientIdentifier: any,
  message: string,
  conceptName: string
) => {
  const formattedRecipientIdentifier = `${recipientIdentifier}`;

  try {
    const requestBody = {
      phone: formattedRecipientIdentifier,
      message: message,
      conceptName: conceptName,
    };

    const response = await axios.post(BASE_URL + `sendSMS`, requestBody);

    // Extract the verification ID from the response data
    const instanceId = response.data.instanceId;
    const checkCode = response.data.checkCode;

    return { instanceId, checkCode }; // Return the verification ID
  } catch (error) {
    // Handle the error as before
    if (axios.isAxiosError(error)) {
      const axiosError = error;
      if (axiosError.response) {
        console.error("Error response from server:", axiosError.response.data);
        console.error("Status code:", axiosError.response.status);
      } else {
        console.error("Request failed:", axiosError.message);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    throw error; // Re-throw the error to propagate it further if needed
  }
};
