// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { User, EmailVerification, Review, Account, SocialLink, Feature, Language, Media, Attachment, Concept, ParentConcept, Group, GroupSetting, CustomerStats, Flag, Interest, ReservationStatus, TimeSlot, Area, PlanItem, Table, Object, Friend, Booking, Comment, Timeline, ServingArea, Call, Notification, AdminRole, AdminGroup, UserConcepts, Transaction, PaymentStat, BookingGuest, Category, MenuItem, Price, ChoiceGroups, Choice, Cart, AdminStatus, OnlineOrder, OrderStatus, DiscountCoupon, MobileNotification, UserPushToken, NotificationReceiver, Zone, UserIP, MainCategory, Shift, PreparationArea, ModelUserConnection, CartItem } = initSchema(schema);

export {
  User,
  EmailVerification,
  Review,
  Account,
  SocialLink,
  Feature,
  Language,
  Media,
  Attachment,
  Concept,
  ParentConcept,
  Group,
  GroupSetting,
  CustomerStats,
  Flag,
  Interest,
  ReservationStatus,
  TimeSlot,
  Area,
  PlanItem,
  Table,
  Object,
  Friend,
  Booking,
  Comment,
  Timeline,
  ServingArea,
  Call,
  Notification,
  AdminRole,
  AdminGroup,
  UserConcepts,
  Transaction,
  PaymentStat,
  BookingGuest,
  Category,
  MenuItem,
  Price,
  ChoiceGroups,
  Choice,
  Cart,
  AdminStatus,
  OnlineOrder,
  OrderStatus,
  DiscountCoupon,
  MobileNotification,
  UserPushToken,
  NotificationReceiver,
  Zone,
  UserIP,
  MainCategory,
  Shift,
  PreparationArea,
  ModelUserConnection,
  CartItem
};