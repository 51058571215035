import { EagerUserConcepts } from "../../models";

// actions
const SET = "userConcepts/SET";
const SET_SELECTED = "userConcepts/SET_SELECTED";

const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: null,
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, {
        conceptsSelected: action.concept,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (single: EagerUserConcepts | null) => ({
  single,
  type: SET_SELECTED,
});
