import { CreateShiftInput, UpdateShiftInput } from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { Shift } from "../models";
import { createShift, updateShift } from "../graphql/mutations";
import { getShift, listShifts } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import { setListing } from "../store/ducks/shift";
import { getDateFormatted } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();

  const session = useSelector((state: any) => state.app.session);
  const shiftsListing = useSelector((state: any) => state.shifts.listing);

  const nextToken= useSelector((state: any) => state.shifts.nextToken);
  const lastIndex= useSelector((state: any) => state.shifts.lastIndex);
  const paginationListing = useSelector((state: any) => state.shifts.pagination);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);


  async function fetch(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText ,limit,startIndex } = params;
      const filter: any = {
        conceptID: { eq: conceptID?conceptID:selectedConcept}, 
      };
     
      const listing: any = await API.graphql<GraphQLQuery<Shift>>({
        query: listShifts,
        variables: { filter, limit: limit ? 1000 : limit},
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

    if (searchText.length > 0)
      {
        let filteredData= listing.data.listShifts.items.filter((item:any)=>{ return item.name.toLowerCase().includes(searchText.toLowerCase())})
        return filteredData;
      }

      return listing.data.listShifts.items;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const shift: any = await API.graphql({
        query: getShift,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return shift.data.getShift;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    try {
      const createInput: CreateShiftInput = {
        name: data.name.toLowerCase(),        
        precedence: data.precedence ? data.precedence : "0",
        startTime: data.startTime ? data.startTime:null,
        endTime: data.endTime ? data.endTime:null,
        conceptID: data.conceptID,
        createdAt: getDateFormatted(new Date()),
        createdByID: userID,
        createdByName: userName,
      };

      const shift = await API.graphql<GraphQLQuery<Shift>>({
        query: createShift,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);

      return shift;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      const updateInput: UpdateShiftInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase() : original.name,
        startTime: data.startTime ? data.startTime:original.startTime,
        endTime: data.endTime ? data.endTime:original.endTime,
        precedence: data.precedence ? data.precedence : original.precedence,
        _version: original._version,
      };

      const Shift: any = await API.graphql<GraphQLQuery<Shift>>({
        query: updateShift,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);

      return Shift.data.updateShift;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: Shift[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = Shift as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: Shift[]) =>
    dispatch(setListing(listing));

  return api;
};

export default useResource;
