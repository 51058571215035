import { listConcepts, listParentConcepts } from "./../graphql/queries";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setBranches,
  setListing,
  setSelected,
  setFilters,
  setSelectedFilters,
} from "../store/ducks/concept";
import { Concept,ParentConcept } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { CreateParentConceptInput } from "../models/GQL_API";
import { GraphQLResult, GraphQLQuery } from "@aws-amplify/api";
import { updateParentConcept } from "../graphql/mutations";
import {
  ConceptBulkTrashVariables,
  ConceptUpdateVariables,
  CreateVariables,
  ConceptGetVariables,
  ConceptListingVariables,
  Option,
} from "../models/app";
import { getParentConcept } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { LOCAL_STORAGE } from "../constants/enums";
const useResource = (listingName: string, singleName: string) => {
  const conceptsListing = useSelector((state: any) => state.concepts.listing);
  const session = useSelector((state: any) => state.app.session);
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: ConceptListingVariables) {
    const { accountID, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        ParentConcept as any,
      );
      return listing;
    } catch (err: Error | any) {
      // showError(err.message);
    }
  }

  async function fetchOnline() {
    // const filter: any = {
    //   deleted: { eq: "0" },
    // };

    try {
      const listing: any = await API.graphql({
        query: listParentConcepts,
        variables: {  limit: 100 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.listParentConcepts.items;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateParentConceptInput = {
        name:data.name?data.name:"",
        type:data.type?data.type:"",
        image:data.image?data.image:"",
        logo:data.logo?data.logo:"",
        venues:data.venues?data.venues:"",
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };
      console.log("starting")
      await DataStore.save(new ParentConcept(createInput as any));
      console.log("finished")
      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      const single: ParentConcept | undefined =
        listing.length === 0
          ? await DataStore.query(ParentConcept as any, id)
          : listing.find((model: any) => model.id === id);

          console.log({single:single})
      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: ConceptUpdateVariables) {
    const { id, listing, data } = params;
    console.log('update test')
    console.log(params)
    try {
      const original:any = await get({ id, listing });
      console.log({data:data})
      // const updated: GraphQLResult<any> = await API.graphql({
      //   query: updateParentConcept,
      //   variables: {
      //     input: {
      //       id: data.id,
      //       type:data.type,
      //       image:data.image,
      //       logo:data.logo,
      //       venues:data.venues,
      //       _version: original._version
      //     },
      //   },
      //   authMode: true
      //     ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      //     : GRAPHQL_AUTH_MODE.AWS_IAM,
      // });
      const updates = await DataStore.save(
        ParentConcept.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name : original!.name;
          updated.index = data.index ? Number(data.index) : Number(original!.index);
          updated.type = data.type ? data.type : original!.type;
          updated.image = data.image ? data.image : original!.image;
          updated.logo = data.logo ? data.logo : original!.logo;
          updated.venues = data.venues ? data.venues : original!.venues;
        })
      );
      showConfirm(`${singleName} has been updated successfully`);
      return updates
    } catch (err) {
      showError(err);
    }
  }


  async function remove(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: ConceptGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: ParentConcept) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  function options(listing: ParentConcept[]) {
    const options: Option[] = [];

    for (let option of listing) {
      if( option.type)
      options.push({ label: option.type, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "index",
      numeric: false,
      disablePadding: false,
      label: "Index",
    },
  ];

  const dataCells: readonly string[] = ["name","type",'index'];

  const api: any = {};

  api[`${listingName}Model`] = ParentConcept as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}Get`] = get;

  api[`${listingName}SetBranches`] = (branches: any) =>
    dispatch(setBranches(branches));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));

  return api;
};

export default useResource;
