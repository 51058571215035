import {
  CreateTransactionObj,
  TransactionGetOnlineVariables,
  TransactionGetVariables,
  TransactionListingVariables,
} from "./../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API, DataStore, SortDirection } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setListing,
  setSelected,
  setEarningAmount,
  setRefundAmount,
  setLinksSent,
  setBookingDateRange,
  setPayStatusFilter,
  setPagination,
  setNextToken,
  setLastIndex,
  setFilter,
  nextAction,
  setSelectedFilters,
} from "../store/ducks/transactions";
import useApp from "./useApp";
import { Transaction } from "../models";
import { CreateTransactionInput } from "../models/GQL_API";
import { createTransaction, updateTransaction } from "../graphql/mutations";
import { bookingTransactions, listTransactions } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import useGroup from "./useGroup";
import useConcept from "./useConcept";
import useTimeSlot from "./useTimeSlot";
import usePlanItem from "./usePlanItem";
import { getDateFormatted } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError } = useApp();
  const { groupsGetName } = useGroup("groups", "group");
  const { conceptsGetName } = useConcept("concepts", "concept");
  const { timeSlotsGetOnline } = useTimeSlot("timeSlots", "timeSlot");
  const { planItemsGetOnline } = usePlanItem("planItems", "planItem");

  const session = useSelector((state: any) => state.app.session);

  // Transaction Pagination
  let nextToken = useSelector((state: any) => state.transactions.nextToken);
  let lastIndex = useSelector((state: any) => state.transactions.lastIndex);
  const storedLimit = useSelector((state: any) => state.transactions.limit);
  const previousTokens = useSelector(
    (state: any) => state.transactions.previousTokens
  );
  let paginationListing = useSelector(
    (state: any) => state.transactions.pagination
  );
  let paginationFilter = useSelector((state: any) => state.transactions.filter);

  async function fetchOffline(props: TransactionListingVariables) {
    const {
      searchText,
      startIndex,
      limit,
      fromDate,
      toDate,
      bookingId,
      bookingDate,
      refund,
      status,
      conceptID,
      groupsSelectedFilters,
      conceptsSelectedFilters,
      transactionsPayStatusFilter,
    } = props;

    try {
      const listing = await DataStore.query(
        Transaction as any,
        (transaction: any) => {
          transaction.deleted("eq", "0");

          if (bookingId) transaction.bookingID("eq", bookingId);
          if (bookingDate) transaction.bookingDate("eq", bookingDate);
          if (refund !== null) transaction.refund("eq", refund);
          if (status !== null) transaction.status("eq", status);
          if (searchText && searchText.length > 0) {
            transaction.or((model: any) => {
              model.transactionID("contains", searchText.toLowerCase());
              model.guestName("contains", searchText.toLowerCase());
              model.guestPhone("contains", searchText.toLowerCase());
            });
          }

          if (fromDate && toDate)
            transaction.date("ge", fromDate).date("le", toDate);

          // Concepts Filter
          if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
            transaction.or((model: any) => {
              for (let filter of conceptsSelectedFilters) {
                model.conceptID("eq", filter.id);
              }
            });
          } else {
            if (conceptID) transaction.conceptID("eq", conceptID);
          }

          // Succuss/refund/failed Filter
          if (
            transactionsPayStatusFilter &&
            transactionsPayStatusFilter.length > 0
          ) {
            transaction.or((model: any) => {
              for (let filter of transactionsPayStatusFilter) {
                model.status("eq", filter.status);
              }
            });
          }

          // Groups Filter
          if (groupsSelectedFilters && groupsSelectedFilters.length > 0) {
            transaction.or((model: any) => {
              for (let filter of groupsSelectedFilters) {
                model.guestGroup("eq", filter.id);
              }
            });
          }

          return transaction;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  // Fetch Online
  async function fetch(props: TransactionListingVariables) {
    const {
      searchText,
      startIndex,
      limit,
      moveForward,
      fromDate,
      toDate,
      bookingId,
      bookingDate,
      refund,
      status,
      conceptID,
      groupsSelectedFilters,
      conceptsSelectedFilters,
      transactionsPayStatusFilter,
    } = props;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };
      if (bookingId) {
        filter.bookingId = { eq: bookingId };
      }
      if (bookingDate) {
        filter.bookingDate = { eq: bookingDate };
      }
      if (refund !== null && refund !== undefined) {
        filter.refund = { eq: refund };
      }
      if (status !== null && status !== undefined) {
        filter.status = { eq: status };
      }

      let and: any[] = [];

      // Date Filter
      if (fromDate && toDate) {
        and.push({
          date: { ge: fromDate },
        });
        and.push({
          date: { le: toDate },
        });
      }

      // Concepts Filter
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let conceptFilter of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: conceptFilter.id } });
        }

        and.push({ or: or });
      } else if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }

      // Succuss/refund/failed Filter
      if (
        transactionsPayStatusFilter &&
        transactionsPayStatusFilter.length > 0
      ) {
        let or = [];
        for (let filter of transactionsPayStatusFilter) {
          or.push({ status: { eq: filter.status } });
        }
        and.push({ or: or });
      }

      // Groups Filter
      if (groupsSelectedFilters && groupsSelectedFilters.length > 0) {
        let or = [];
        for (let filter of groupsSelectedFilters) {
          or.push({ guestGroup: { eq: filter.id } });
        }
        and.push({ or: or });
      }

      if (searchText && searchText.length > 0) {
        let or = [];
        or.push({ transactionID: { contains: searchText.toLowerCase() } });
        or.push({ guestName: { contains: searchText.toLowerCase() } });
        or.push({ guestPhone: { contains: searchText.toLowerCase() } });

        and.push({ or: or });
      }

      if (and.length > 0) {
        filter.and = and;
      }

      // Query transactions
      let itemsList: any = null;
      let listing: any[] = [];
      let currentNextToken;
      let requestLimit = limit ? limit : storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;

      // Reset the pagination if the filter has changed.
      if (
        paginationFilter &&
        paginationFilter.toString() !==
          [
            searchText,
            conceptID,
            conceptsSelectedFilters,
            fromDate,
            toDate,
          ].toString()
      ) {
        dispatch(setPagination([]));
        dispatch(setNextToken(null));
        dispatch(setLastIndex(0));

        paginationListing = [];
        requestToken = null;
      }

      itemsList = await API.graphql<GraphQLQuery<Transaction>>({
        query: listTransactions,
        variables: {
          filter,
          limit: 5000,
          nextToken: requestToken,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      listing = itemsList.data.listTransactions.items;

      // Sort by creation date
      if (listing.length > 0) {
        let ordersList = listing.sort((a: any, b: any) => {
          return b.createdAt > a.createdAt ? 1 : -1;
        });
        return ordersList;
      }

      // Update Store
      if (moveForward) {
        currentNextToken = itemsList?.data?.listTransactions?.nextToken;
        dispatch(nextAction(currentNextToken, requestPreviousTokens));
        dispatch(setNextToken(currentNextToken));
      }

      dispatch(setPagination(paginationListing.concat(listing)));
      dispatch(
        setFilter([
          searchText,
          conceptID,
          conceptsSelectedFilters,
          fromDate,
          toDate,
        ])
      );

      return listing;
    } catch (err: Error | any) {
      showError(err);
      return [];
    }
  }

  async function get(params: TransactionGetVariables) {
    const { id } = params;

    try {
      const single: Transaction | undefined = await DataStore.query(
        Transaction as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(params: TransactionGetOnlineVariables) {
    try {
      const { id, transactionID, bookingID, bookingDate } = params;

      const listing = await DataStore.query(
        Transaction as any,
        (transaction: any) => {
          transaction.deleted("eq", "0");
          if (id) transaction.id("eq", id);
          if (transactionID) transaction.transactionID("eq", transactionID);
          if (bookingID) transaction.bookingId("eq", bookingID);
          if (bookingDate) transaction.bookingDate("eq", bookingDate);
          return transaction;
        },
        {
          page: 0,
          limit: 1,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function getByBookingIdOnline(bookingID: string) {
    try {
      const transactions: any = await API.graphql<GraphQLQuery<Transaction>>({
        query: bookingTransactions,
        variables: { bookingID },
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // // Sort by creation date from new to old
      let sortedTransactions: Transaction[] =
        transactions?.data?.bookingTransactions?.items;
      sortedTransactions.sort((a, b) => {
        // Convert them to date type
        let dateA = new Date(a.createdAt);
        let dateB = new Date(b.createdAt);

        if (dateA > dateB) {
          return -1; // new to old
        }
        if (dateA < dateB) {
          return 1; // old to new
        }
        return 0; // no change
      });

      return sortedTransactions;
    } catch (err) {
      showError(err);
    }
  }

  async function update(data: any) {
    try {
    } catch (err) {
      showError(err);
    }
  }

  async function updateOnline(params: any, session = null) {
    try {
      const transaction: any = await API.graphql<GraphQLQuery<Transaction>>({
        query: updateTransaction,
        variables: { input: params },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return transaction;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: CreateTransactionObj, isAuth = null) {
    try {
      if (data.timeSlots && data.timeSlots.length > 0) {
        let _timeSlots: any[] = [];
        let count = 0;
        for (let id of data.timeSlots) {
          let timeSlot = await timeSlotsGetOnline(id, session);
          if (timeSlot) {
            _timeSlots.push(timeSlot.friendlyName);
            count++;
          }
        }

        if (count === data.timeSlots.length) {
          data.timeSlots = _timeSlots;
        }
      }

      if (data.tables && data.tables.length > 0) {
        let _tables: any[] = [];
        let count = 0;
        for (let id of data.tables) {
          let planItems = await planItemsGetOnline(id, session);
          if (planItems) {
            _tables.push(planItems.name);
            count++;
          }
        }

        if (count === data.tables.length) {
          data.tables = _tables;
        }
      }

      const createInput: CreateTransactionInput = {
        transactionID: data.transactionID,
        date: getDateFormatted(new Date()),
        guestName: data.guestName,
        guestPhone: data.guestPhone,
        guestID: data.guestID,
        guestGroup: data.guestGroup,
        guestsNames: data.guestsNames,
        bookingID: data.bookingID,
        bookingDate: data.bookingDate,
        amount_cents: data.amount_cents,
        status: data.status!,
        failureReason: data.failureReason,
        type: data.type,
        timeSlots: data.timeSlots,
        tables: data.tables,
        conceptID: data.conceptID,
        currency: data.currency,
        refund: data.refund!,
        orderID: data.orderID,
        ownerID: data.ownerID,
        refunded_amount_cents: data.refunded_amount_cents,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
      };

      // await DataStore.save(new Object(createInput as any));
      const transaction = await API.graphql<GraphQLQuery<Transaction>>({
        query: createTransaction,
        variables: { input: createInput },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return transaction;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: TransactionListingVariables) {
    const data = await fetch(params);

    let exportedData = [];

    for (let transaction of data!) {
      let row: any = { ...transaction };

      if (row.status && !row.refund) {
        row.status = "Success";
      } else if (row.refund) {
        row.status = "Refunded";
      } else {
        row.status = "Failed";
      }

      if (transaction.guestGroup) {
        row.guestGroup = groupsGetName({
          id: transaction.guestGroup,
          listing: params.groupsListing ? params.groupsListing : [],
        });
      }

      // if (transaction.tables) {
      //   row.tables = planItemsGetNames({
      //     tables: new Set(transaction.tables),
      //     listing: params.planItemsListing ? params.planItemsListing : [],
      //   });
      // }

      if (transaction.conceptID) {
        row.conceptID = conceptsGetName({
          id: transaction.conceptID,
          listing: params.conceptsListing ? params.conceptsListing : [],
        });
      }

      exportedData.push(row);
    }

    return exportedData;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "transactionID",
      numeric: false,
      disablePadding: false,
      label: "Trx ID",
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Booking Date",
    },
    {
      id: "guestName",
      numeric: false,
      disablePadding: false,
      label: "Guest Name",
    },
    {
      id: "guestsNames",
      numeric: false,
      disablePadding: false,
      label: "Event Guests",
    },
    {
      id: "guestPhone",
      numeric: false,
      disablePadding: false,
      label: "Mobile",
    },
    {
      id: "guestGroup",
      numeric: false,
      disablePadding: false,
      label: "Group",
    },
    {
      id: "amount_cents",
      numeric: false,
      disablePadding: false,
      label: "Amount",
    },
    {
      id: "currency",
      numeric: false,
      disablePadding: false,
      label: "Currency",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Payment Status",
    },
    {
      id: "failureReason",
      numeric: false,
      disablePadding: false,
      label: "Details",
    },
    {
      id: "tables",
      numeric: false,
      disablePadding: false,
      label: "Table",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "type",
    },
    {
      id: "conceptID",
      numeric: false,
      disablePadding: false,
      label: "Concept",
    },
    {
      id: "timeSlots",
      numeric: false,
      disablePadding: false,
      label: "Time Slot",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Creation Time & Date",
    },
  ];

  const dataCells: readonly string[] = [
    "transactionID",
    "date",
    "guestName",
    "guestsNames",
    "guestPhone",
    "guestGroup",
    "amount_cents",
    "currency",
    "status",
    "failureReason",
    "tables",
    "type",
    "conceptID",
    "timeSlots",
    "createdAt",
  ];

  const api: any = {};

  api[`${listingName}Model`] = Transaction as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Update`] = update;
  api[`${listingName}UpdateOnline`] = updateOnline;
  api[`${listingName}GetByBookingIdOnline`] = getByBookingIdOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Export`] = exportAll;

  api[`${listingName}ChangeListing`] = (listing: Transaction[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}ChangeEarningAmount`] = (earningAmount: number) =>
    dispatch(setEarningAmount(earningAmount));
  api[`${listingName}ChangeRefundAmount`] = (refundAmount: number) =>
    dispatch(setRefundAmount(refundAmount));
  api[`${listingName}ChangeLinksSent`] = (linksSent: number) =>
    dispatch(setLinksSent(linksSent));
  api[`${listingName}ChangePayStatusFilter`] = (filters: any) =>
    dispatch(setPayStatusFilter(filters));
  api[`${listingName}ChangeBookingDateRange`] = (bookingDateRange: any) =>
    dispatch(setBookingDateRange(bookingDateRange));

  return api;
};

export default useResource;
