// actions
const SET = "admins/SET";
const SET_SELECTED = "admins/SET_SELECTED";
const SET_FILTERS = "admins/SET_FILTERS";
const SET_SELECTED_FILTERS = "admins/SET_SELECTED_FILTERS";
const SET_DISPATCHER_LISTING = "admins/SET_DISPATCHER_LISTING";
const SET_WAITER_LISTING = "admins/SET_WAITER_LISTING";


const DEFAULT_STATE = {
  listing: [],
  dispatcherlisting: [],
  waiterlisting: [],
  selected: "",
  filters: [],
  selectedFilters: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_DISPATCHER_LISTING:
      return Object.assign({}, state, { dispatcherListing: action.listing });
    case SET_WAITER_LISTING:
      return Object.assign({}, state, { waiterlisting: action.listing });
    case SET_FILTERS:
      return Object.assign({}, state, { filters: action.filters });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setFilters = (filters: string[]) => ({
  filters,
  type: SET_FILTERS,
});
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
export const setDispatcherListing = (listing: any[]) => ({
  listing,
  type: SET_DISPATCHER_LISTING,
});
export const setWaiterListing = (listing: any[]) => ({
  listing,
  type: SET_WAITER_LISTING,
});
