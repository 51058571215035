// actions
const SET = "tables/SET";
const SET_SELECTED = "tables/SET_SELECTED";

const DEFAULT_STATE = {
  listing: [],
  selected: "",
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
