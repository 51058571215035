import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useEffect } from 'react';
type Props = {
  name: string;
  defaultValue: any;
  setValue: any;
  register: any;
};

export default function TimePickerValue({
  name,
  defaultValue,
  setValue,
  register,
  }:Props) {
  const [time, setTime] = React.useState<any | null>(dayjs('2000-01-01T00:00'));
  useEffect(() => {
    register(name);
    setValue(name,"00:00")
    if(defaultValue!=="")
    {
      setTime(dayjs("2000-01-01T"+defaultValue))
    }
  }, []);
  const handelChange=(newValue:any)=>{
   let selectedTime= (((new Date(newValue.$d).getHours().toString().length)===1? ("0"+new Date(newValue.$d).getHours().toString()) :(new Date(newValue.$d).getHours().toString())) 
    +":" +(( new Date(newValue.$d).getMinutes().toString().length===1)?( "0"+ new Date(newValue.$d).getMinutes().toString() ):(new Date(newValue.$d).getMinutes().toString())))
    setValue(name,selectedTime)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker', 'TimePicker']}>
        <TimePicker
          value={time}
          onChange={(newValue:any) => {setTime(newValue);handelChange(newValue);}}
        //  onAccept={(newValue:any) => {console.log(newValue)}}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}