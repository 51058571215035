// actions
const SET = "orderStatuses/SET";
const SET_FILTERS = "orderStatuses/SET_FILTERS";
const SET_SELECTED_FILTERS = "orderStatuses/SET_SELECTED_FILTERS";

const DEFAULT_STATE = {
  listing: [],
  filters: [],
  selectedFilters: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.listing });
    case SET_FILTERS:
      return Object.assign({}, state, { filters: action.filters });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setFilters = (filters: string[]) => ({
  filters,
  type: SET_FILTERS,
});
export const setSelectedFilters = (listing: any[]) => ({
  listing,
  type: SET_SELECTED_FILTERS,
});
