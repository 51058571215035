import { CreateMenuItemInput, UpdateMenuItemInput } from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { ChoiceGroups, MenuItem } from "../models";
import { createMenuItem, updateMenuItem } from "../graphql/mutations";
import { getMenuItem, listMenuItems } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import {
  setListing,
  setNextToken,
  setLastIndex,
  setPagination,
} from "../store/ducks/menuItem";
import { extractSelectedCheckboxes, getDateFormatted } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm, showWarning } = useApp();

  const session = useSelector((state: any) => state.app.session);
  const menuItemsListing = useSelector((state: any) => state.menuItems.listing);
  const priceListing = useSelector((state: any) => state.prices.listing);

  const nextToken = useSelector((state: any) => state.menuItems.nextToken);
  const lastIndex = useSelector((state: any) => state.menuItems.lastIndex);
  const paginationListing = useSelector(
    (state: any) => state.menuItems.pagination
  );
  const selectedConcept = useSelector((state: any) => state.concepts.selected);

  async function fetch(params: ListingByConceptVariables) {
    // console.log(nextToken)
    // console.log(lastIndex)
    try {
      const { conceptID, searchText, limit, startIndex } = params;
      const filter: any = {
        conceptID: { eq: conceptID ? conceptID : selectedConcept },
      };
      if (
        menuItemsListing.length === 0 ||
        conceptID !== menuItemsListing[0].conceptID ||
        menuItemsListing.length <= 30
      ) {
        if (!(limit > 1000) || !limit) {
          if (
            lastIndex >= startIndex &&
            lastIndex !== null &&
            paginationListing.length > 0 &&
            selectedConcept === paginationListing[0].conceptID
          ) {
            if (searchText.length > 0) {
              let filteredData = paginationListing.filter((item: any) => {
                return item.name
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              });
              return filteredData;
            }
            return paginationListing.slice(startIndex, startIndex + limit);
          }
          const listing: any = await API.graphql<GraphQLQuery<MenuItem>>({
            query: listMenuItems,
            variables: {
              filter,
              limit: limit ?? 1000,
              nextToken:
                paginationListing.length > 0 &&
                selectedConcept === paginationListing[0]?.conceptID
                  ? nextToken
                  : undefined,
            },
            authMode: session
              ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
              : GRAPHQL_AUTH_MODE.AWS_IAM,
          });
          dispatch(setLastIndex(startIndex));
          dispatch(setNextToken(listing.data.listMenuItems.nextToken));
          dispatch(
            setPagination(
              paginationListing.concat(listing.data.listMenuItems.items)
            )
          );

          if (searchText.length > 0) {
            let filteredData = listing.data.listMenuItems.items.filter(
              (item: any) => {
                return item.name
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              }
            );
            return filteredData;
          }
          return listing.data.listMenuItems.items;
        } else {
          const firstlisting: any = await API.graphql<GraphQLQuery<MenuItem>>({
            query: listMenuItems,
            variables: { filter, limit: limit ?? 10000 },
            authMode: session
              ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
              : GRAPHQL_AUTH_MODE.AWS_IAM,
          });

          let nextToken = firstlisting.data.listMenuItems.nextToken;
          let allMenuItems = firstlisting.data.listMenuItems.items;

          while (nextToken && nextToken.length > 0) {
            const listing: any = await API.graphql<GraphQLQuery<MenuItem>>({
              query: listMenuItems,
              variables: { filter, limit: limit ?? 10000, nextToken },
              authMode: session
                ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
                : GRAPHQL_AUTH_MODE.AWS_IAM,
            });

            allMenuItems = allMenuItems.concat(
              listing.data.listMenuItems.items
            );
            nextToken = listing.data.listMenuItems.nextToken;
          }
          if (searchText.length > 0) {
            let filteredData = allMenuItems.filter((item: any) => {
              return item.name.toLowerCase().includes(searchText.toLowerCase());
            });
            return filteredData;
          }
          return allMenuItems;
        }
      } else {
        return menuItemsListing;
      }
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const menuItem: any = await API.graphql({
        query: getMenuItem,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return menuItem.data.getMenuItem;
    } catch (err) {
      throw err;
    }
  }

  async function getChoiceGroups(resourceId: string) {
    try {
      const choiceGroups: ChoiceGroups[] = [];

      const single: MenuItem | undefined = await get(resourceId);

      if (!single) return choiceGroups;

      if (single.choiceGroups && single.choiceGroups.length === 0)
        return choiceGroups;

      if (single.choiceGroups && single.choiceGroups.length > 0) {
        return choiceGroups;
      }
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    try {
      if (!data.name) {
        showError("Name is required");
        return;
      }

      if (!data.categoryID) {
        showError("Category is required");
        return;
      }
      const choiceGroups: string[] = extractSelectedCheckboxes(
        "choiceGroups_",
        data
      );

      let prices: any = new Set();
      if (priceListing.length === 0) {
        showError("Price is required");
        return null;
      } else {
        for (let price of priceListing) {
          prices.add(price.id);
        }
        prices = Array.from(prices);
      }

      const createInput: CreateMenuItemInput = {
        conceptID: data.conceptID,

        name: data.name.toLowerCase(),
        description: data.description ? data.description : "Bon Appetit",
        image: data.image
          ? data.image.fileUrl
            ? data.image.fileUrl
            : data.image
          : "1690451817942-New-York-Style Pizza.jpg",
        categoryID: data.categoryID,
        outOfStock: data.outOfStock ? data.outOfStock : false,
        enabled: data.enabled ? data.enabled : true,
        symphonyID: data.symphonyID ? data.symphonyID : "0",
        prices: prices,
        choiceGroups: choiceGroups ? choiceGroups : [],
        hasLimitedQuantity: data.hasLimitedQuantity
          ? data.hasLimitedQuantity
          : false,
        quantity: data.quantity ? parseInt(data.quantity) : 0,
        autoRestock: data.autoRestock ? data.autoRestock : false,
        restockQuantity: data.restockQuantity
          ? parseInt(data.restockQuantity)
          : 0,

        createdAt: getDateFormatted(new Date()),
        createdByID: userID,
        createdByName: userName,
      };

      const MenuItem = await API.graphql<GraphQLQuery<MenuItem>>({
        query: createMenuItem,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);

      return MenuItem;
    } catch (err) {
      showWarning(`Failed to create new ${singleName}`);
      console.log(err);
    }
  }

  async function update(params: any, session: any) {
    try {
      const { data } = params;
      let original: any = [];
      if (data.itemCounts) {
        for (let item in data.itemCounts) {
          let menuItem = await get({ id: item });
          original.push(menuItem);
          if (
            !(menuItem.quantity >= data.itemCounts[item]) &&
            menuItem.hasLimitedQuantity
          ) {
            showError(
              "Only " + menuItem.quantity + " items left of " + menuItem.name
            );
            return null;
          }
        }
      } else if (data.previousQuantity) {
        for (let item in data.previousQuantity) {
          let menuItem = await get({ id: item });
          original.push(menuItem);
        }
      } else {
        let menuItem = await get(params);
        original.push(menuItem);
      }
      let MenuItem: any = [];
      for (let i = 0; i < original.length; i++) {
        let finalQuantity = original.quantity;

        if (data.quantity !== undefined) {
          finalQuantity = data.quantity
            ? parseInt(data.quantity)
            : original[i]!.quantity;
        }
        if (data.action !== null && original[i].hasLimitedQuantity) {
          if (data.action === "create") {
            finalQuantity =
              original[i].quantity - data.itemCounts[original[i].id];
          }
          if (data.action === "update") {
            finalQuantity =
              original[i].quantity +
              (data.previousQuantity[original[i].id] -
                data.itemCounts[original[i].id]);
          }
          if (data.action === "cancel") {
            finalQuantity =
              original[i].quantity + data.previousQuantity[original[i].id];
          }
        }
        //  console.log(finalQuantity)

        let prices: any = new Set(original[i]!.prices);
        let choiceGroups: any = new Set();
        const updatedChoiceGroups: string[] = extractSelectedCheckboxes(
          "choiceGroups_",
          data
        );

        if (data.prices && data.prices.length > 0) {
          for (let price of data.prices) {
            prices.add(price);
          }
          prices = Array.from(prices);
        }
        // if(prices.length==0)
        // {
        //   showError("Item Must have at least one price");
        // }

        if (updatedChoiceGroups && updatedChoiceGroups.length > 0) {
          for (let choiceGroup of updatedChoiceGroups) {
            choiceGroups.add(choiceGroup);
          }
          choiceGroups = Array.from(choiceGroups);
        } else {
          choiceGroups = new Set(original[i]!.choiceGroups);
        }

        const updateInput: UpdateMenuItemInput = {
          id: original[i].id,

          name: data.name ? data.name.toLowerCase() : original[i]!.name,
          description: data.description
            ? data.description
            : original[i]!.description,
          image: data.image
            ? data.image.fileUrl
              ? data.image.fileUrl
              : data.image
            : original[i]!.image,
          categoryID: data.categoryID
            ? data.categoryID
            : original[i]!.categoryID,
          outOfStock:
            data.outOfStock !== undefined
              ? data.outOfStock
              : original[i]!.outOfStock,
          enabled:
            data.enabled !== undefined ? data.enabled : original[i]!.enabled,
          symphonyID: data.symphonyID
            ? data.symphonyID
            : original[i]!.symphonyID,
          prices: data.prices ? prices : original[i]!.prices,
          choiceGroups:
            updatedChoiceGroups.length > 0
              ? choiceGroups
              : original[i]!.choiceGroups,
          hasLimitedQuantity: data.hasLimitedQuantity
            ? data.hasLimitedQuantity
            : original[i]!.hasLimitedQuantity,
          quantity: finalQuantity,
          autoRestock: data.autoRestock
            ? data.autoRestock
            : original[i]!.autoRestock,
          restockQuantity: data.restockQuantity
            ? parseInt(data.restockQuantity)
            : original[i]!.restockQuantity,

          _version: original[i]._version,
        };

        MenuItem = await API.graphql<GraphQLQuery<MenuItem>>({
          query: updateMenuItem,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }
      showConfirm(`${singleName} has been updated successfully`);
      if (data.itemCounts || data.previousQuantity) {
        return true;
      } else {
        return MenuItem.data.updateMenuItem;
      }
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: ListingByConceptVariables) {
    try {
      const data = await fetch(params);

      let exportedData = [];

      for (let menuItem of data!) {
        let row: any = { ...menuItem };

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  function options(listing: MenuItem[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "Stock Quantity",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name", "description", "quantity"];

  const api: any = {};

  api[`${listingName}Model`] = MenuItem as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetChoiceGroups`] = getChoiceGroups;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: MenuItem[]) =>
    dispatch(setListing(listing));

  return api;
};

export default useResource;
