import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/customerStats";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { CustomerStats } from "../models";
import {
  CreateVariables,
  CustomerStatsBulkTrashVariables,
  CustomerStatsUpdateVariables,
  CustomerStatsGetVariables,
  CustomerStatsListingVariables,
} from "../models/app";
import { CreateCustomerStatsInput } from "../models/GQL_API";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: CustomerStatsListingVariables) {
    const { accountID, searchText, startIndex, limit } = params;

    try {
      const listing = await DataStore.query(
        CustomerStats as any,
        (model: any) => {
          model.accountID("eq", accountID).deleted("eq", "0");

          if (searchText.length > 0)
            model.message("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      // console.log(err);
      showError(err.message);
    }
  }

  async function get(params: CustomerStatsGetVariables) {
    const { id, listing } = params;

    try {
      const single: CustomerStats | undefined =
        listing.length === 0
          ? await DataStore.query(CustomerStats as any, id)
          : listing.find((model: CustomerStats) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateCustomerStatsInput = {
        userID: data.userID,
        conceptID: data.conceptID,
        totalReservation: data.totalReservation,
        confirmed: data.confirmed,
        notConfirmed: data.notConfirmed,
        canceled: data.canceled,
        checkIn: data.checkIn,
        noShow: data.noShow,
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const model = await DataStore.save(new CustomerStats(createInput as any));

      // // console.log(`New ${singleName} has been created successfully`);

      return model.id;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: CustomerStatsUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      await DataStore.save(
        CustomerStats.copyOf(original!, (updated) => {
          updated.totalReservation = data.totalReservation
            ? data.totalReservation
            : original!.totalReservation;
          updated.confirmed = data.confirmed
            ? data.confirmed
            : original!.confirmed;
          updated.checkIn = data.checkIn ? data.checkIn : original!.checkIn;
          updated.noShow = data.noShow ? data.noShow : original!.noShow;
          updated.notConfirmed = data.notConfirmed
            ? data.notConfirmed
            : original!.notConfirmed;
          updated.canceled = data.canceled ? data.canceled : original!.canceled;
        })
      );
      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: CustomerStatsGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        CustomerStats.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: CustomerStatsBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: CustomerStatsGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "category"];

  const api: any = {};

  api[`${listingName}Model`] = CustomerStats as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: CustomerStats[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
