import { DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setListing, setSelected } from "../store/ducks/userConcepts";
import { EagerUserConcepts, UserConcepts } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import {
  UserConceptsGetVariables,
  CreateVariables,
  UserConceptsBulkTrashVariables,
  UserConceptsUpdateVariables,
  Option,
  GetVariables,
  UserConceptsListingVariables,
} from "../models/app";
import { CreateUserConceptsInput } from "../models/GQL_API";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: UserConceptsListingVariables) {
    const { searchText, startIndex, limit, userID } = params;

    try {
      const listing = await DataStore.query(
        UserConcepts as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (userID) model.createdByID("eq", userID);

          if (searchText.length > 0)
            model.defaultConcept("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: UserConcepts | undefined = await DataStore.query(
        UserConcepts as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    const listingParams: UserConceptsListingVariables = {
      startIndex: 0,
      limit: 1000,
      searchText: "",
      userID,
    };

    const existed = await fetch(listingParams);

    if (existed!.length > 0) return;

    try {
      const createInput: CreateUserConceptsInput = {
        defaultConcept: data.defaultConcept ? data.defaultConcept : "",
        concepts: data.concepts ? data.concepts : [],
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      const model = await DataStore.save(new UserConcepts(createInput as any));

      return model;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: UserConceptsUpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        UserConcepts.copyOf(original!, (updated) => {
          updated.defaultConcept = data.defaultConcept
            ? data.defaultConcept
            : original!.defaultConcept;
          updated.concepts = data.concepts ? data.concepts : original!.concepts;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        UserConcepts.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: UserConceptsBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: UserConceptsGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: UserConcepts[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.id, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = UserConcepts as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: UserConcepts[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (selected: EagerUserConcepts | null) =>
    dispatch(setSelected(selected));

  return api;
};

export default useResource;
